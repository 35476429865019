import React, {useEffect, useState} from 'react';
import {connect, useDispatch, useSelector, useStore} from 'react-redux';
import {IColumn, Panel, PanelType, registerIcons, ScrollablePane, ScrollbarVisibility, Text,} from '@fluentui/react';
import {useTranslation} from 'react-i18next';
import {mergeStyleSets} from '@fluentui/react/lib/Styling';
import {DayPlanningListItem, DayPlanningListPaneFilter} from '../../../types/dayPlanning';
import moment from 'moment';
import '../../../shared/kanban/kanban.css';
import {
    formatDateTimeToISOString,
    getCalenderLocalizer,
    getProject,
    getTimeZone,
    isMyEnvironment,
    MSG_AUTO_CLOSE_TIME,
} from '../../../shared/util';
import {
    assignMyDayPlanningTool,
    assignPlanningPbs,
    assignPlanningProject,
    assignVehicleToTeam,
    createNewTeamDayPlanning,
    filterMyDayPlanningResourceListPaneData,
    filterMyDayPlanningTeamsData,
    saveMyDayPlanningStateAttr,
    updateMyDayPlanningLabour,
    updateMyDayPlanningPmol,
    updateMyDayPlanningPmolPerson,
    updateMyDayPlanningVehicle,
} from '../../../reducers/myDayPlanningReducer';
import {AcceptType, DpViewMode, GridView} from '../../../types/myDayPlanning';
import _ from 'lodash';
import 'react-big-calendar/lib/addons/dragAndDrop';
import 'react-big-calendar/lib/addons/dragAndDrop/styles.css';
import usePrevious from '../../../hooks/usePrevious';
import {messageService} from '../../../services/messageService';
import {SvgIcons} from '../../../shared/svgIcons/svgIcons';
import {savePMOLStateAttr} from '../../../reducers/pmolReducer';
import CIAWModal from '../../projectDayPlanning/listPane/ciawModal';
import {saveProjectDayPlanningStateAttr,} from '../../../reducers/projectDayPlanningReducer';
import CustomAlert from '../../../shared/alert/customAlert';
import ConfirmationDialog from '../../../shared/confirmationDialog/confirmationDialog';
import RFQModal from '../../projectDayPlanning/listPane/rfqModal';
import {saveDayPlanningStateAttr} from '../../../reducers/dayPlanningReducer';
import CreatePmolModal from '../../dayPlanning/pmolListPane/component/newPmol';
import CopyPmolModal from "../../../shared/PlanboardComponents/CopyPmolModal/component";
import DndCalendar from "./dndCalendar";
import { saveVPStateAttr } from '../../../reducers/visualPlanReducer';


registerIcons(SvgIcons);

export const classNames = mergeStyleSets({
    wrapper: {
        // height: 'calc(100vh - 32vh)!important',
        position: 'relative',
    },
    wrapperFullScreen: {
        height: 'calc(100vh - 11vh)!important',
        position: 'relative',
    },
    filter: {
        paddingBottom: 20,
        maxWidth: 100,
    },
    header: {
        margin: 0,
    },
    row: {
        display: 'inline-block',
    },
});

export const formatTimeForPmol = (date: string, time: any) => {
    let formatted = moment(date).format('YYYY/MM/DD')
    let dateonly = (formatted === 'Invalid date') ? '' : formatted.toString();
    if (dateonly && time) {
        let t = time ? time : '';
        let dateTime = dateonly + " " + t;
        return moment(dateTime).toDate();
    } else {
        return moment.utc(date).local().toDate();
    }
};

const ListPaneComponent = (props: {
    filter: DayPlanningListPaneFilter;
    selection: any;
    columns: IColumn[];
    listPaneItems: DayPlanningListItem[];
    handleListPaneItemClick: (id: string, project: string) => void;
    handleFilterChange: (filter: DayPlanningListPaneFilter) => void;
    isDataLoaded?: boolean;
    loadMsg: string;
    resetSelection: () => void;
    dropDownOptions: any;
    horizontalReSizer: any;
}) => {

    const {t} = useTranslation()
    const dispatch = useDispatch();
    const store = useStore();
    // const [width, height] = useWindowSize();

    const [filter, setFilter] = useState<DayPlanningListPaneFilter>(props.filter)
    const [resources, setResources]: any = useState(null);
    const [dayResources, setDayResources]: any = useState(null);
    const [pmolList, setPmolList]: any = useState(null);
    const [pmolDpStatus, setPmolDpStatus] = useState<any>(null);
    const [jobStatus, setJobStatus] = useState<any>(undefined);
    const [selectedPersons, setSelectedPersons] = useState<any>(null);
    const [showMsg, setShowMsg]: any = useState(false);

    const view = useSelector((state: any) => state?.mydPlan?.view);
    const {
        selectedDate,
        selectedWeek,
        teamList,
        showFullScreen,
        loadMsgRfq,
        planboardPersonId,
        selectedMyDpPmol,
        createdTransportPmol,
        isUpdatePmolDayPlanningSuccess,
        absenceUpdateSucces,
        TPBShortcutPaneResizer
    } = useSelector(
        (state: any) => state.mydPlan
    );

    const userInfo = useSelector((state: any) => state?.uprince?.userInfo);

    const {newPMOLCreated, isEdit} = useSelector(
        (state: any) => state.pmol
    );

    const {selectedCu, selectedBu, buDropDown} = useSelector(
        (state: any) => state.projectDPlan
    );

    const {selectedProject} = useSelector(
        (state: any) => state.project,
    );

    const createNewPmolSuccess = useSelector((state: any) => state?.dplan?.createNewPmolSuccess);
    const clonePmolId = useSelector((state: any) => state?.pmol?.clonePmolId);

    const prevView: any = usePrevious<any>(view);
    const prevDate: any = usePrevious<any>(selectedDate);
    // const prevStartDate: any = usePrevious<any>(selectedWeek.startDate);

    const isBuSelectedInDropdown = (): Boolean => {
        return buDropDown.some((item: {
            key: any;
        }) => item.key === selectedProject?.projectClassification?.projectClassificationBuisnessUnit);
    }

    useEffect(() => {
        // console.log('didmount');
        getCalenderLocalizer();
        // dispatch(filterMyDayPlanningTeamsData(getFilterDate(true)));
        dispatch(saveProjectDayPlanningStateAttr('isCIAWgenerated', false));

        const subscription = messageService.getMessage().subscribe((data: any) => {
            if (data) {
                if (data.data.pmolDpStatus) {
                    setPmolDpStatus(data.data.pmolDpStatus)
                }

                if(data.data.jobStatus){
                    let status = null;
                    if(data.data.jobStatus == 2){
                      status = true;
                    } else if(data.data.jobStatus == 3){
                      status = false;
                    }
                    setJobStatus(status)
                }

                if(data.data.selectedPersons){
                    setSelectedPersons(data.data.selectedPersons);
                }

                if (data.data.dndItem) {
                    if (data.data.dndItem.type == AcceptType.PMOL) {
                        pmolAssignToTeam(data.data.dndItem);
                    } else if (data.data.dndItem.type == AcceptType.TEAM) {
                        if (data.data.dndItem?.isPmol) {
                            updatePmolPerson(data.data.dndItem);
                        } else {
                            createTeam(data.data.dndItem);
                        }
                    } else if (data.data.dndItem.type == AcceptType.VEHICLE || data.data.dndItem.type == AcceptType.TOOL) {
                        if(data.data.dndItem.isPmol){
                            updateResources(data.data.dndItem);
                        } else if(!data.data.dndItem.isPmol && data.data.dndItem.type == AcceptType.VEHICLE){
                            assignVehicleToTeamApi(data.data.dndItem);
                        }
                    } else if (data.data.dndItem.type == AcceptType.PERSON) {
                        updateLabour(data.data.dndItem)
                    } else if (data.data.dndItem.type == AcceptType.PBS) {
                        pbsAssign(data.data.dndItem);
                    } else if (data.data.dndItem.type == AcceptType.PROJECT) {
                        projectAssign(data.data.dndItem);
                    }
                }

                if (data.data.pmolDndItem) {
                    if (data.data.pmolDndItem.type == AcceptType.TEAM) {
                        updatePmolPerson(data.data.pmolDndItem);
                    } else if (data.data.pmolDndItem.type == AcceptType.VEHICLE) {
                        updateResources(data.data.pmolDndItem);
                    } else if (data.data.pmolDndItem.type == AcceptType.TOOL) {
                        assignPmolTools(data.data.pmolDndItem);
                    }
                }

                if (data.data.reloadList) {
                    reloadListPaneItem();
                }

                if (data.data.didDrop == false) {
                    if(data.data.isDeleteVehicle){
                        deleteVehicleFromTeam(data.data.item.dragItem);
                    } else {
                        removeLabour(data.data?.draggedItem)
                    }                    
                }
            }
        });

        const interval = setInterval(() => {
            reloadFullScreen();
        }, 600000);

        return () => {
            subscription.unsubscribe();
            dispatch(saveMyDayPlanningStateAttr('teamList', []));
            dispatch(saveMyDayPlanningStateAttr('myDpViewMode', DpViewMode.Default));
            clearInterval(interval);
            dispatch(saveMyDayPlanningStateAttr('pmolCopyState', {event: {}, isCopyModalOpen: false}));
        }
    }, []);

    useEffect(() => {
        setFilter(props.filter)
    }, [props.filter])

    useEffect(() => {
        getTeam(teamList)
    }, [teamList]);

    useEffect(() => {
        if (prevView && prevView !== view) {
            reloadListPaneItem();
        }
    }, [view]);

    useEffect(() => {
        if (prevDate && moment(prevDate).format('YYYY-MM-DD') !== moment(selectedDate).format('YYYY-MM-DD')) {
            reloadListPaneItem();
        }
    }, [selectedDate]);

    useEffect(() => {
        if (!_.isEmpty(selectedWeek)) {
            reloadListPaneItem();
        }
    }, [selectedWeek]);

    useEffect(() => {
        if (pmolDpStatus) {
            reloadListPaneItem();
        }
    }, [pmolDpStatus]);

    useEffect(() => {
            reloadListPaneItem();
    }, [jobStatus]);

    useEffect(() => {
        if(selectedPersons && selectedPersons.length>0){
            reloadListPaneItem();
        }
    }, [selectedPersons])

    useEffect(() => {
        if (!_.isNull(planboardPersonId)) {
            reloadListPaneItem();
        }
    }, [planboardPersonId]);

    useEffect(() => {
        if (selectedCu && selectedBu) {
            reloadListPaneItem();
        }
    }, [selectedCu, selectedBu]);

    useEffect(() => {
        if (newPMOLCreated) {
            reloadListPaneItem();
        }

        return () => {
            dispatch(savePMOLStateAttr('newPMOLCreated', false));
        }
    }, [newPMOLCreated]);

    useEffect(() => {
        if (createNewPmolSuccess) {
            reloadListPaneItem();
        }

        return () => {
            dispatch(saveDayPlanningStateAttr('createNewPmolSuccess', false));
        }
    }, [createNewPmolSuccess]);

    useEffect(() => {
        if (loadMsgRfq) {
            setShowMsg(true)
            setTimeout(() => {
                setShowMsg(false)
                dispatch(saveMyDayPlanningStateAttr('loadMsgRfq', ''))
            }, MSG_AUTO_CLOSE_TIME)
        }
    }, [loadMsgRfq]);

    useEffect(() => {
        dispatch(saveMyDayPlanningStateAttr('horizontalReSizer', props.horizontalReSizer));
    }, [props.horizontalReSizer])

    useEffect(() => {
        if (clonePmolId) {
            reloadListPaneItem();
            dispatch(saveMyDayPlanningStateAttr('reloadBottomListPane', true));
        }

        return () => {
            dispatch(savePMOLStateAttr('clonePmolId', null));
        }
    }, [clonePmolId]);

    useEffect(() => {
        if (createdTransportPmol) {
            reloadListPaneItem();
        }
        return () => {
            dispatch(saveMyDayPlanningStateAttr('createdTransportPmol', false));
        }
    }, [createdTransportPmol]);

    useEffect(() => {
        if (isUpdatePmolDayPlanningSuccess) {
            reloadListPaneItem();
        }
        return () => {
            dispatch(saveMyDayPlanningStateAttr('isUpdatePmolDayPlanningSuccess', false));
        }
    }, [isUpdatePmolDayPlanningSuccess]);

    useEffect(() => {
        if(absenceUpdateSucces){
            reloadListPaneItem();
            refreshBottomListPane();
            dispatch(saveMyDayPlanningStateAttr('absenceUpdateSucces', false));
        }
    }, [absenceUpdateSucces])

    const getTeam = (teamList: any) => {
        if (teamList && teamList.length > 0) {
            let emptyColumn = [{
                resourceId: 0,
                resourceTitle: `${t('createNewTeam')}`,
                team: [],
                teamId: null,
                teamTitle: null,
                contractingUinit: null
            }]
            let teams = teamList.map((items: any, index: any) => {
                const cu = items?.contractingUinit ? items?.contractingUinit : '-';

                let teamName = items?.team?.map((emp: any) => {
                    return emp.name
                })                

                // teamName.push(`(${items?.teamTitle})`);
                return {
                    resourceId: 1 + index,
                    resourceTitle: teamName?.join(","),
                    team: items?.team,
                    teamId: items?.teamId,
                    teamTitle: items?.teamTitle,
                    contractingUinit: cu,
                    vehicles: items?.teamVehicle,
                }
            })
            // const dayTeams = emptyColumn.concat(teams)

            setResources(teams)
            setDayResources(teams)
            getPmol(teamList)
        } else {
            setResources(null)
            setDayResources(null)
            setPmolList([])
        }
    };

    const getPmol = (teamList: any) => {
        let pmolList: any[] = [];

        teamList.map((team: any, index: any) => {
            if (team && team?.pmol && team.pmol.length > 0) {
                team?.pmol?.map((pmol: any) => {
                    pmolList.push({
                        id: pmol?.id,
                        teamId: pmol?.teamId,
                        projectMoleculeId: pmol?.projectMoleculeId,
                        projectSequenceCode: pmol?.projectSequenceCode,
                        contractingUinit: pmol?.contractingUinit,
                        title: pmol?.title,
                        executionDate: moment(pmol?.executionDate).format('YYYY/MM/DD'),
                        start: formatTimeForPmol(pmol.executionDate, pmol.executionStartTime),
                        end: formatTimeForPmol(pmol.executionDate, pmol.executionEndTime),
                        resourceId: 1 + index,
                        typeId: pmol?.typeId,
                        pmolVehical: pmol?.pomlVehical,
                        pmolTool: pmol?.pomlTool,
                        statusId: pmol?.statusId,
                        projectTitle: pmol?.projectTitle,
                        forecast: pmol?.forecast,
                        executionStartTime: pmol?.executionStartTime,
                        executionEndTime: pmol?.executionEndTime,
                        productTaxonomy: pmol?.productTaxonomy,
                        comment: pmol?.comment,
                        isFinished: pmol?.isFinished
                    })
                    //   return list
                })
            }
            return pmolList
        })
        setPmolList(pmolList)
    };

    const getFilterDate = () => {
        const currentDate = moment();

        return {
            startDate: store.getState()?.mydPlan?.view === GridView.DAY
              ? moment(store.getState()?.mydPlan?.selectedDate).format('YYYY-MM-DD')
              : store.getState()?.mydPlan?.selectedWeek.startDate ? moment(store.getState()?.mydPlan?.selectedWeek.startDate).format('YYYY-MM-DD') : moment(currentDate).clone().startOf('isoWeek').format('YYYY-MM-DD'),
            endDate: store.getState()?.mydPlan?.view === GridView.DAY
              ? moment(store.getState()?.mydPlan?.selectedDate).format('YYYY-MM-DD')
              : store.getState()?.mydPlan?.selectedWeek.endDate ? moment(store.getState()?.mydPlan?.selectedWeek.endDate).format('YYYY-MM-DD') : moment(currentDate).clone().endOf('isoWeek').format('YYYY-MM-DD'),
            localDate: moment(currentDate).format('YYYY-MM-DD'),
            offSet: getTimeZone(),
            type: store.getState()?.mydPlan?.view,
            pmolStatus: pmolDpStatus === '0' ? null : pmolDpStatus,
            contractingUnit: store.getState()?.uprince?.userCu ? store.getState()?.uprince?.userCu?.sequenceCode : null,
            weekEndDate: store.getState()?.mydPlan?.view === GridView.DAY
                ? moment(store.getState()?.mydPlan?.selectedDate).clone().endOf('isoWeek').format('YYYY-MM-DD')
                : store.getState()?.mydPlan?.selectedWeek.endDate ? moment(store.getState()?.mydPlan?.selectedWeek.endDate).format('YYYY-MM-DD') : moment(currentDate).clone().endOf('isoWeek').format('YYYY-MM-DD'),
            buId: isMyEnvironment() ? store.getState()?.projectDPlan?.selectedBu ?? null : isBuSelectedInDropdown() ? selectedProject?.projectClassification?.projectClassificationBuisnessUnit : null,
            cabPersonId: planboardPersonId ? planboardPersonId : null,
            isJobDone: jobStatus!==undefined?jobStatus:null,
            cabPersonIds : selectedPersons?selectedPersons:null,
        }
    };

    const reloadListPaneItem = () => {
        dispatch(filterMyDayPlanningTeamsData(getFilterDate()));
    };

    const reloadFullScreen = () => {
        if (store.getState()?.mydPlan?.showFullScreen) {
            dispatch(filterMyDayPlanningTeamsData(getFilterDate()));
        }
    };

    const displayMessage = () => {
        return (
            <div style={{paddingTop: 36, paddingLeft: 20}}>
                <Text>{t(props.loadMsg)}</Text>
            </div>
        );
    };

    const updateEvent = (event: any, start: any, end: any, resourceId: any) => {
        const idx = pmolList.indexOf(event);
        const updatedEvent = {...event, start, end, resourceId};

        const nextEvents = [...pmolList];
        nextEvents.splice(idx, 1, updatedEvent);

        setPmolList(nextEvents)
    };

    const pmolAssignToTeam = (dndItem: any) => {
        let data = {
            teamId: dndItem?.dropItem?.teamId,
            team: dndItem?.dropItem?.team,
            id: dndItem?.dragItem?.id,
            executionDate: moment(dndItem?.dropItem?.date).format('YYYY/MM/DD'),
            executionStartTime: dndItem?.dragItem?.start ? moment(dndItem?.dragItem?.start).format('HH:mm') : null,
            executionEndTime: dndItem?.dragItem?.end ? moment(dndItem?.dragItem?.end).format('HH:mm') : null,
            projectSequenceCode: isMyEnvironment() ? dndItem?.dragItem?.projectSequenceCode : getProject(),
            contractingUnit: dndItem?.dragItem?.contractingUinit
        };
        Promise.all([
            dispatch(updateMyDayPlanningPmol(data))
        ]).then(() => {
            reloadListPaneItem();
            dispatch(saveMyDayPlanningStateAttr('reloadFilters', true));
        });
    };

    const pbsAssign = (dndItem: any) => {

        let data = {
            teamId: dndItem?.dropItem?.teamId,
            id: dndItem?.dragItem?.id,
            weekStartDate: moment(dndItem?.dropItem?.date).format('YYYY/MM/DD'),
            weekEndDate: moment(dndItem?.dropItem?.date).format('YYYY/MM/DD'),
            executionDate: moment(dndItem?.dropItem?.date).format('YYYY/MM/DD'),
            projectSequenceCode: dndItem?.dragItem?.projectSequenceCode,
            contractingUnit: dndItem?.dropItem?.contractingUinit,
            buId: dndItem?.dropItem?.buId,
        };

        // console.log("data", data);
        Promise.all([
            dispatch(assignPlanningPbs(data))
        ]).then(() => {
            reloadListPaneItem();
            dispatch(saveMyDayPlanningStateAttr('reloadFilters', true));
        });
    };

    const projectAssign = (dndItem: any) => {
        let data = {
            teamId: dndItem?.dropItem?.teamId,
            executionDate: moment(dndItem?.dropItem?.date).format('YYYY/MM/DD'),
            projectSequenceCode: dndItem?.dragItem?.projectSequenceCode,
            contractingUnit: dndItem?.dropItem?.contractingUinit,
            weekStartDate: moment(dndItem?.dropItem?.date).format('YYYY/MM/DD'),
            weekEndDate: moment(dndItem?.dropItem?.date).format('YYYY/MM/DD'),
        };

        Promise.all([
            dispatch(assignPlanningProject(data))
        ]).then(() => {
            reloadListPaneItem();
            dispatch(saveMyDayPlanningStateAttr('reloadFilters', true));
        });
    };

    const createTeam = (dndItem: any) => {
        let data = {
            teamId: dndItem?.dropItem?.teamId ? dndItem?.dropItem?.teamId : null,
            projectSequenceCode: dndItem?.dragItem?.projectSequenceCode,
            personId: dndItem?.dragItem?.id,
            personName: dndItem?.dragItem?.name,
            contractingUnit: store.getState()?.projectDPlan?.selectedCu ? store.getState()?.projectDPlan?.selectedCu?.sequenceCode : userInfo ? userInfo?.contractingUnit?.sequnceCode : [],
            executionDate: moment(dndItem?.dragItem?.day).format('YYYY/MM/DD'),
            buId: store.getState()?.projectDPlan?.selectedBu ? store.getState()?.projectDPlan?.selectedBu : null,
        };
        Promise.all([
            dispatch(createNewTeamDayPlanning(data))
        ]).then(() => {
            reloadListPaneItem();
            dispatch(saveMyDayPlanningStateAttr('reloadBottomListPane', true));
        });
    };

    const updateResources = (dndItem: any) => {
        let data = {
            teamId: dndItem?.dropItem?.teamId,
            coperateProductCatalogId: dndItem?.dragItem?.coperateProductCatalogId
        };
        Promise.all([
            dispatch(updateMyDayPlanningVehicle(data))
        ]).then(() => {
            reloadListPaneItem();
            dispatch(saveMyDayPlanningStateAttr('reloadBottomListPane', true));
        });
    };

    const refreshBottomListPane = () => {
        let data = {
            startDate: store.getState()?.mydPlan?.view === GridView.DAY
                    ? moment(store.getState()?.mydPlan?.selectedDate).format('YYYY-MM-DD')
                    : store.getState()?.mydPlan?.selectedWeek.startDate ? moment(store.getState()?.mydPlan?.selectedWeek.startDate).format('YYYY-MM-DD') : moment(selectedDate).startOf('isoWeek').format('YYYY-MM-DD'),
                endDate: store.getState()?.mydPlan?.view === GridView.DAY
                    ? moment(store.getState()?.mydPlan?.selectedDate).format('YYYY-MM-DD')
                    : store.getState()?.mydPlan?.selectedWeek.endDate ? moment(store.getState()?.mydPlan?.selectedWeek.endDate).format('YYYY-MM-DD') : moment(selectedDate).clone().endOf('isoWeek').format('YYYY-MM-DD'),
                localDate: moment(selectedDate).format('YYYY-MM-DD'),
                offSet: getTimeZone(),
                type: store.getState()?.mydPlan?.view,
                contractingUnit: store.getState()?.projectDPlan?.selectedCu ? store.getState()?.projectDPlan?.selectedCu?.sequenceCode : userInfo ? userInfo?.contractingUnit?.sequnceCode : [],
                buId: store.getState()?.projectDPlan?.selectedBu ? store.getState()?.projectDPlan?.selectedBu : null,
        }

        dispatch(filterMyDayPlanningResourceListPaneData(data));
    }

    const deleteVehicleFromTeam = (item: any) => {
        let data = {
            FromTeamId: item.fromTeamId,
            ToTeamId: null,
            CoperateProductCatalogId: item.coperateProductCatalogId,
            Action: "Delete",
            CurrentDate: store.getState()?.mydPlan?.view === GridView.DAY
            ? moment(store.getState()?.mydPlan?.selectedDate).format('YYYY-MM-DD')
            : store.getState()?.mydPlan?.selectedWeek.startDate ? moment(store.getState()?.mydPlan?.selectedWeek.startDate).format('YYYY-MM-DD') : moment(selectedDate).startOf('isoWeek').format('YYYY-MM-DD'),
        }

        if(data.FromTeamId && data.CoperateProductCatalogId){
            Promise.all([
                dispatch(assignVehicleToTeam(data))
            ]).then(() => {
                reloadListPaneItem();
                refreshBottomListPane();
            });
        }
    }

    const assignVehicleToTeamApi = (dndItem: any) => {
        let data = null;
        if(dndItem.dragItem.fromTeamId===null && dndItem.dropItem.teamId){
            data = {
                FromTeamId: dndItem.dragItem.fromTeamId,
                ToTeamId: dndItem.dropItem.teamId,
                CoperateProductCatalogId: dndItem.dragItem.coperateProductCatalogId,
                Action: "Assign",
                CurrentDate: store.getState()?.mydPlan?.view === GridView.DAY
                ? moment(store.getState()?.mydPlan?.selectedDate).format('YYYY-MM-DD')
                : store.getState()?.mydPlan?.selectedWeek.startDate ? moment(store.getState()?.mydPlan?.selectedWeek.startDate).format('YYYY-MM-DD') : moment(selectedDate).startOf('isoWeek').format('YYYY-MM-DD'),
            }
        } else if(dndItem.dragItem.fromTeamId && dndItem.dropItem.teamId) {
            data = {
                FromTeamId: dndItem.dragItem.fromTeamId,
                ToTeamId: dndItem.dropItem.teamId,
                CoperateProductCatalogId: dndItem.dragItem.coperateProductCatalogId,
                Action: "Move",
                CurrentDate: store.getState()?.mydPlan?.view === GridView.DAY
                ? moment(store.getState()?.mydPlan?.selectedDate).format('YYYY-MM-DD')
                : store.getState()?.mydPlan?.selectedWeek.startDate ? moment(store.getState()?.mydPlan?.selectedWeek.startDate).format('YYYY-MM-DD') : moment(selectedDate).startOf('isoWeek').format('YYYY-MM-DD'),
            }
        }
        
        Promise.all([
            dispatch(assignVehicleToTeam(data))
        ]).then(() => {
            reloadListPaneItem();
            refreshBottomListPane();
        });

    };

    const updatePmolPerson = (dndItem: any) => {
        let data = {
            teamId: dndItem?.dropItem?.teamId,
            id: dndItem?.dropItem?.id,
            executionDate: dndItem?.dropItem?.executionDate,
            executionStartTime: moment(dndItem?.dropItem?.start).format('HH:mm'),
            executionEndTime: moment(dndItem?.dropItem?.end).format('HH:mm'),
            projectSequenceCode: dndItem?.dropItem?.projectSequenceCode,
            contractingUnit: dndItem?.dropItem?.contractingUinit,
            personId: dndItem?.dragItem?.id,
            personName: dndItem?.dragItem?.name,
        };
        Promise.all([
            dispatch(updateMyDayPlanningPmolPerson(data))
        ]).then(() => {
            // reloadListPaneItem();
            // dispatch(saveMyDayPlanningStateAttr('reloadBottomListPane', true));
        });
    };

    const assignPmolTools = (dndItem: any) => {
        let data = {
            id: dndItem?.dropItem?.id,
            projectSequenceCode: dndItem?.dropItem?.projectSequenceCode,
            contractingUinit: dndItem?.dropItem?.contractingUinit,
            allocatedQuantity: dndItem?.dragItem?.allocatedQuantity ? dndItem?.dragItem?.allocatedQuantity : 0,
            requiredQuantity: dndItem?.dragItem?.requiredQuantity ? dndItem?.dragItem?.requiredQuantity : 0,
            coperateProductCatalogId: dndItem?.dragItem?.coperateProductCatalogId
        };
        Promise.all([
            dispatch(assignMyDayPlanningTool(data))
        ]).then(() => {
            reloadListPaneItem();
            dispatch(saveMyDayPlanningStateAttr('reloadBottomListPane', true));
        });
    };

    const updateLabour = (dndItem: any) => {
        let data = {
            teamId: dndItem?.dragItem?.resource?.teamId,
            cabPersonId: dndItem?.dragItem?.cabPerson?.id,
            newTeamId: dndItem?.dropItem?.teamId,
            executionDate: store.getState()?.mydPlan?.view == 'day' ?
              moment(store.getState()?.mydPlan?.selectedDate).format('YYYY-MM-DD') :
              _.isEmpty(store.getState()?.mydPlan?.selectedWeek) ?
                moment(new Date()).startOf('isoWeek').format('YYYY-MM-DD') :
                store.getState()?.mydPlan?.selectedWeek.startDate,
        };
        Promise.all([
            dispatch(updateMyDayPlanningLabour(data))
        ]).then(() => {
            reloadListPaneItem();
            dispatch(saveMyDayPlanningStateAttr('reloadBottomListPane', true));
        });
    };

    const removeLabour = (dndItem: any) => {
        let data = {
            teamId: dndItem?.dragItem?.resource?.teamId,
            cabPersonId: dndItem?.dragItem?.cabPerson?.id,
            newTeamId: null,
        };

        // console.log(data)
        if (data.cabPersonId) {
            Promise.all([
                dispatch(updateMyDayPlanningLabour(data))
            ]).then(() => {
                reloadListPaneItem();
                dispatch(saveMyDayPlanningStateAttr('reloadBottomListPane', true));
            });
        }
    };

    const renderListPane = () => {
        return <div style={{height: "100%", width: "100%"}} className='plan-board-calender-with-borders'>
            <DndCalendar
                events={pmolList ?? []}
                view={view}
                resources={{dayResources: dayResources, resources: resources}}
                updateEvent={updateEvent}
            />
        </div>
    }

    const onRemoveConfirm = () => {
        // dispatch(saveProjectDayPlanningStateAttr('isConfirmDeleteModalOpen', true));
    };

    const onRemoveCancel = () => {
        // dispatch(saveProjectDayPlanningStateAttr('isConfirmDeleteModalOpen', true));
    };


    return <div className={'pdp'} style={{width: '100%', height: '100%'}}>
        <CustomAlert
            showMsg={showMsg}
            msg={loadMsgRfq}
            msgType={'failed'}
            handleCustomAlert={() => {
                setShowMsg(false)
            }}
        />
        {/*<div style={{width:(width-255)}} className={`wrapper-holder ${showFullScreen ? classNames.wrapperFullScreen : classNames.wrapper}`}>*/}
        <ScrollablePane scrollbarVisibility={ScrollbarVisibility.auto} style={{marginLeft: TPBShortcutPaneResizer?TPBShortcutPaneResizer+255:255, marginTop: 80}}>
            {renderListPane()}
            {props.isDataLoaded && displayMessage()}
        </ScrollablePane>
        {/*</div>*/}
        <Panel
            isOpen={showFullScreen}
            type={PanelType.custom}
            customWidth="100vw"
            onDismiss={() => {
                dispatch(saveMyDayPlanningStateAttr('showFullScreen', false))
            }}
            isLightDismiss={true}
            headerText={t('teamPlanboard')}
            className="custom-detail-panel pdp"
            closeButtonAriaLabel={t('close')}
        >
            {/*CommandBarLeft is already used in the main layout */}
            {/*<div style={{ display: 'flex' }}>*/}
            {/*  <CommandBarLeft onSelectDate={(date: any) => { dispatch(saveMyDayPlanningStateAttr('selectedDate', date)) }} disable={false} isTopPane={true} refresh={() => { reloadFullScreen() }} />*/}
            {/*</div>*/}

            <div className={'project-day_planing'} style={{
                width: '100%',
                height: screen.height - 250,
                overflow: 'overlay',
                paddingLeft: 5
            }}> {renderListPane()}</div>
        </Panel>

        <CIAWModal/>
        <RFQModal/>
        <CreatePmolModal fCode={"day-planning-new"}/>

        <ConfirmationDialog
            hidden={true}
            title={t('areyousure')}
            subText={`${t('Are you sure you want to remove ') + 'selectedWorker'}?`}
            onClickConfirmDialog={onRemoveConfirm}
            onClickCancelDialog={onRemoveCancel}
            cancelButtonText={t('notRemove')}
            confirmButtonText={t('remove')}
        />

        <CopyPmolModal/>

    </div>
};

const mapStateToProps = (state: any) => {
    return {
        toleranceStatesFilter: state.projectBreakdown.toleranceStatesFilter,
        productStatesFilter: state.projectBreakdown.productStatesFilter,
        itemTypesFilter: state.projectBreakdown.itemTypesFilter,
    };
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(ListPaneComponent);
