import {getTomorrow} from "../shared/util";
import moment from "moment/moment";

export class CiawListItem {
    id: string | null = null;
    cabPersonId: string | null = null;
    ciawStatus: string | null = null;
    date: string | null = null;
    project: string | null = null;
    createdBy: string | null = null;
    modifiedBy: string | null = null;
    createdDate: string | null = null;
    modifiedDate: string | null = null;
    reference: string | null = null;
    pmolTeamRoleId: string | null = null;
    cabPersonName: string | null = null;
    // organisation: string | null = null
}

export class CiawFilterData {
  cabPerson: string | null = null;
  ciawStatus: string | null = null;
  isCiawEligible: string | null = null;
  date: string | null = moment(getTomorrow()).format('YYYY-MM-DD');
  project: string | null = null;
  sorter: Sorter = new Sorter();
  platform: string | null = null;
  certificationStatuses: CertificationStatus[] = [];
  organisation: string | null = null
//   certificationStatuses: certificationStatuses = new certificationStatuses();
}

class Sorter {
    attribute: string | null = null;
    order: string | null = null;
}

class CertificationStatus {
    title: string | null = null;
    weeks: string | null = null;
  
    constructor(title: string | null = null, weeks: string | null = null) {
      this.title = title;
      this.weeks = weeks;
    }
  }

export class CiawSiteCode {
    id: string | null = null;
    ciawSiteCode: string | null = null;
    ciawSeverEntry: string | null = null;
    projectId: string | null = null;
    isCiawEnabled: boolean = false;
    isSocialSecurityWebsite: boolean = true;
    isCustomerPortal: boolean = false;
    customerPortalComments:string |null=null;
}

export class CiawSiteproductCode {
    id: string | null = null;
    ciawSiteCode: string | null = null;
    ciawSeverEntry: string | null = null;
    pbsproductId: string | null = null;
    isCiawEnabled: boolean = false;
    isSocialSecurityWebsite: boolean = true;
    isCustomerPortal: boolean = false;
    customerPortalComments:string |null=null;
}

export enum CERTIFICATION_TAXONOMY_IDS {
    BELGIUM_NATIONALITY_ID = '06f491c0-2be9-4d28-bcd8-c330d6a4aeac',
    A1_ID = "962621f9-19c7-4647-bc69-643bca88ab56",
    LIMOSA_ID = "24aa5219-b510-4aac-8024-a4d40c8a9060",
    SSNI_ID = "87c045e4-cfc5-4a9e-be7c-c4755880a7d7",
}

export const defaultCertificateData = [
    {
        "id": "",
        "personId": "",
        "startDate": undefined,
        "endDate": undefined,
        "certificationTaxonomyId": CERTIFICATION_TAXONOMY_IDS.SSNI_ID,
        "title": "SSLI",
        "certificationTitle": "-",
        "certificationUrl": "",
        "validity": true,
    },
    {
        "id": "",
        "personId": "",
        "startDate": undefined,
        "endDate": undefined,
        "certificationTaxonomyId": CERTIFICATION_TAXONOMY_IDS.LIMOSA_ID,
        "title": "Limosa",
        "certificationTitle": "-",
        "certificationUrl": "",
        "validity": true,
    },
    {
        "id": "",
        "personId": "",
        "startDate": undefined,
        "endDate": undefined,
        "certificationTaxonomyId": CERTIFICATION_TAXONOMY_IDS.A1_ID,
        "title": "A1",
        "certificationTitle": "-",
        "certificationUrl": "",
        "validity": true,
    }
]

export interface CiawFormData {
    id: string | null,
    cabPersonId: string | null,
    cabPersonName: string | null,
    date: Date | undefined,
    project: string | null,
    projectTitle: string | null,
    status: DropDown,
    projectManager: string | null,
    ciawCode: string | null,
    ciawRegistrationStatus: string | null,
    nationality: string | null,
    cabCertification: string | null,
    projectCiawCode: string | null,
    organisation: string | null,
    orgCiawCode: number,
    orgCountryCode: string | null,
    orgCountryName: string | null,
    certificationId: string | null,
    errorWarning: string | null,
    remarks: any,
    isCustomerPortal:boolean | null,
    customerPortalComments:string | null,
    eligibilityError: any
}

interface DropDown {
    key: string | null
    text: string | null
}