import UprinceLogger from '../shared/Logger/logger';
import { format24hDateTime, formatwithoutTime } from '../shared/util';
import {
  APPROVED_PS_CU_EP,
  APPROVED_PS_EP,
  CREATE_PS_RESOURCE_EP,
  GET_VAT_EP,
  PRICE_LIST_READ_EP,
  PRICE_LIST_SAVE_EP,
  PS_RESOURCE_CONSUMABLES_READ_EP,
  PS_RESOURCE_LABOUR_READ_EP,
  PS_RESOURCE_MATERIALS_READ_EP,
  PS_RESOURCE_TOOLS_READ_EP,
  PS_SAVE_EP,
  READ_COST_LIST_EP,
  READ_PS_BY_ID_EP,
  READ_PS_DROPDOWN_EP,
  READ_PS_LIST_EP,
  READ_PS_SHORTCUT_PANE_EP,
  RESOURCE_DELETE_EP,
  RESOURCE_SAVE_EP,
  SAVE_VAT_EP,
  REMOVE_PROJECTCOST
} from '../shared/endpoints';
import {
  APPROVED_PS,
  APPROVED_PS_CU,
  APPROVED_PS_CU_FAIL,
  APPROVED_PS_CU_SUCCESS,
  APPROVED_PS_FAIL,
  APPROVED_PS_SUCCESS,
  CLEAR_PS_FORM_DATA,
  CLEAR_PS_UID,
  CREATE_PS_RESOURCE,
  CREATE_PS_RESOURCE_FAIL,
  CREATE_PS_RESOURCE_SUCCESS,
  DELETE_RESOURCE,
  DELETE_RESOURCE_FAIL,
  DELETE_RESOURCE_SUCCESS,
  LOAD_COST_LIST,
  LOAD_COST_LIST_FAIL,
  LOAD_COST_LIST_SUCCESS,
  LOAD_PS_BY_ID,
  LOAD_PS_BY_ID_FAIL,
  LOAD_PS_BY_ID_SUCCESS,
  LOAD_PS_DROPDOWN,
  LOAD_PS_DROPDOWN_FAIL,
  LOAD_PS_DROPDOWN_SUCCESS,
  LOAD_PS_LSITPANE,
  LOAD_PS_LSITPANE_FAIL,
  LOAD_PS_LSITPANE_SUCCESS,
  LOAD_PS_SHORTCUTPANE,
  LOAD_PS_SHORTCUTPANE_FAIL,
  LOAD_PS_SHORTCUTPANE_SUCCESS,
  LOAD_RESOURCE_CONSUMABLES,
  LOAD_RESOURCE_CONSUMABLES_FAIL,
  LOAD_RESOURCE_CONSUMABLES_SUCCESS,
  LOAD_RESOURCE_LABOUR,
  LOAD_RESOURCE_LABOUR_FAIL,
  LOAD_RESOURCE_LABOUR_SUCCESS,
  LOAD_RESOURCE_MATERIALS,
  LOAD_RESOURCE_MATERIALS_FAIL,
  LOAD_RESOURCE_MATERIALS_SUCCESS,
  LOAD_RESOURCE_TOOLS,
  LOAD_RESOURCE_TOOLS_FAIL,
  LOAD_RESOURCE_TOOLS_SUCCESS,
  LOAD_VAT_LIST,
  LOAD_VAT_LIST_FAIL,
  LOAD_VAT_LIST_SUCCESS,
  READ_PRICE_LIST,
  READ_PRICE_LIST_FAIL,
  READ_PRICE_LIST_SUCCESS,
  RESET_PS_IS_CHANGE,
  SAVE_PRICE_LIST,
  SAVE_PRICE_LIST_FAIL,
  SAVE_PRICE_LIST_SUCCESS,
  SAVE_PS,
  SAVE_PS_FAIL,
  SAVE_PS_SUCCESS,
  SAVE_RESOURCE,
  SAVE_RESOURCE_FAIL,
  SAVE_RESOURCE_SUCCESS,
  SAVE_VAT,
  SAVE_VAT_FAIL,
  SAVE_VAT_SUCCESS,
  SET_PS_UID,
  REMOVE_PC,
  REMOVE_PC_SUCCESS,
  REMOVE_PC_FAIL
} from '../actionTypes/progressStatementActionTypes';
import { CostFilter, ProgressStatement, PSListPaneFilter } from '../types/progressStatement';

import i18n from '../../i18n';
import { v4 as uuidv4 } from 'uuid';
import { ListPaneItem } from '../../../src/app/types/progressStatement';


const defaultState: any = {
  psShortCutdata: [],
  listPaneItems: [],
  isPSListLoaded: false,
  isNew: false,
  isChange: false,
  isEdit: false,
  showProgressBar: false,
  psDropDowns: {},
  formData: {},
  uid: null,
  loadMsg: '',
  costList: [],
  isLoadingCostLIst: false,
  priceList: {},
  readMaterialsStatus: false,
  createMaterialsStatus: false,
  materials: [],
  readToolsStatus: false,
  createToolsStatus: false,
  tools: [],
  readConsumableStatus: false,
  createConsumableStatus: false,
  consumables: [],
  readLabourStatus: false,
  createLabourStatus: false,
  labours: [],
  vatList: [],
  isPsFormDataLoaded: false,
};

export default function psReducer(state = defaultState, action: any) {
  if (action) {
    switch (action.type) {
      case LOAD_PS_SHORTCUTPANE:
        // UprinceLogger.log('LOAD_PS_SHORTCUTPANE', action);
        return { ...state };
      case LOAD_PS_SHORTCUTPANE_SUCCESS:
        // UprinceLogger.log('LOAD_PS_SHORTCUTPANE_SUCCESS', action);
        return {
          ...state,
          psShortCutdata: action.payload.data.result,
        };
      case LOAD_PS_SHORTCUTPANE_FAIL:
        // UprinceLogger.log(
        //   'LOAD_PS_SHORTCUTPANE_FAIL',
        //   action.error.response.data.message
        // );
        return { ...state };

      case LOAD_PS_LSITPANE:
        // UprinceLogger.log('LOAD_PS_LSITPANE', action);
        return { ...state, isPSListLoaded: false };
      case LOAD_PS_LSITPANE_SUCCESS:
        // UprinceLogger.log('LOAD_PS_LSITPANE_SUCCESS', action);
        return {
          ...state,
          isChange: false,
          isPSListLoaded: true,
          listPaneItems: formattedPSList(action.payload.data.result),
          loadMsg: !action.payload.data.status
            ? action.payload.data.message
            : '',
        };
      case LOAD_PS_LSITPANE_FAIL:
        // UprinceLogger.log(
        //   'LOAD_PS_LSITPANE_FAIL',
        //   action.error.response.data.message
        // );
        return {
          ...state,
          isPSListLoaded: true,
          listPaneItems: [],
          loadMsg: action.error.response.data.message,
        };


      case RESET_PS_IS_CHANGE:
        return {
          ...state,
          isChange: false,
        };

      case LOAD_PS_DROPDOWN:
        // UprinceLogger.log('LOAD_PS_DROPDOWN', action);
        return { ...state, psDropDowns: {} };
      case LOAD_PS_DROPDOWN_SUCCESS:
        // UprinceLogger.log('LOAD_PS_DROPDOWN_SUCCESS', action);
        return {
          ...state,
          psDropDowns: action.payload.data.result,
        };
      case LOAD_PS_DROPDOWN_FAIL:
        // UprinceLogger.log(
        //   'LOAD_PS_DROPDOWN_FAIL',
        //   action.error.response.data.message
        // );
        return {
          ...state,
          psDropDowns: {},
        };

      case LOAD_PS_BY_ID:
        // UprinceLogger.log('LOAD_PS_BY_ID', action);
        return { ...state, formData: {}, showProgressBar: true, isPsFormDataLoaded: false };
      case LOAD_PS_BY_ID_SUCCESS:
        UprinceLogger.log('LOAD_PS_BY_ID_SUCCESS', action);
        return {
          ...state,
          isEdit: true,
          formData: action.payload.data.result,
          showProgressBar: false,
          isPsFormDataLoaded: true,
        };
      case LOAD_PS_BY_ID_FAIL:
        // UprinceLogger.log(
        //   'LOAD_PS_BY_ID_FAIL',
        //   action.error.response.data.message
        // );
        return {
          ...state,
          formData: {},
          showProgressBar: false,
          isPsFormDataLoaded: true,
        };

      case SAVE_PS:
        return { ...state, showProgressBar: true, isPsFormDataLoaded: false };
      case SAVE_PS_SUCCESS:
        return {
          ...state,
          isChange: true,
          isNew: false,
          showProgressBar: false,
        };
      case SAVE_PS_FAIL:
        return {
          ...state,
          showProgressBar: false,
        };

      case SET_PS_UID:
        return {
          ...state,
          isEdit: false,
          uid: uuidv4() + '-' + Date.now(),
        };
      case CLEAR_PS_UID:
        return {
          ...state,
          uid: null,
          isChange: false,
        };
      case CLEAR_PS_FORM_DATA:
        return {
          ...state,
          uid: null,
          isNew: true,
          isChange: false,
          formData: {},
        };
      case LOAD_COST_LIST:
        return { ...state, isLoadingCostLIst: true, costList: [] };
      case LOAD_COST_LIST_SUCCESS:
        return {
          ...state,
          isLoadingCostLIst: false,
          costList: formattedCostList(action.payload.data.result),
        };
      case LOAD_COST_LIST_FAIL:
        return {
          ...state,
          isLoadingCostLIst: false,
          costList: [],
        };

      case SAVE_PRICE_LIST:
        return { ...state, showProgressBar: true };
      case SAVE_PRICE_LIST_SUCCESS:
        return {
          ...state,
          showProgressBar: false,
        };
      case SAVE_PRICE_LIST_FAIL:
        return {
          ...state,
          showProgressBar: false,
        };
        case REMOVE_PC:
          return { ...state, showProgressBar: true };
        case REMOVE_PC_SUCCESS:
          return {
            ...state,
            showProgressBar: false,
          };
        case REMOVE_PC_FAIL:
          return {
            ...state,
            showProgressBar: false,
          };

      case READ_PRICE_LIST:
        // UprinceLogger.log('LOAD_PS_BY_ID', action);
        return { ...state, showProgressBar: true };
      case READ_PRICE_LIST_SUCCESS:
        // UprinceLogger.log('LOAD_PS_BY_ID_SUCCESS', action);
        return {
          ...state,
          priceList: action.payload.data.result,
          showProgressBar: false,
        };
      case READ_PRICE_LIST_FAIL:
        // UprinceLogger.log(
        //   'LOAD_PS_BY_ID_FAIL',
        //   action.error.response.data.message
        // );
        return {
          ...state,
          priceList: {},
          showProgressBar: false,
        };

      case SAVE_RESOURCE:
        return { ...state, showProgressBar: true, isPsFormDataLoaded: false };
      case SAVE_RESOURCE_SUCCESS:
        return {
          ...state,
          showProgressBar: false,
        };
      case SAVE_RESOURCE_FAIL:
        return {
          ...state,
          showProgressBar: false,
        };

      case LOAD_RESOURCE_MATERIALS:
        return { ...state, readMaterialsStatus: true, materials: [] };
      case LOAD_RESOURCE_MATERIALS_SUCCESS:
        return {
          ...state,
          readMaterialsStatus: false,
          createMaterialsStatus: false,
          materials: action.payload.data.result,
        };
      case LOAD_RESOURCE_MATERIALS_FAIL:
        return {
          ...state,
          readMaterialsStatus: false,
        };

      case LOAD_RESOURCE_TOOLS:
        return { ...state, readToolsStatus: true, tools: [] };
      case LOAD_RESOURCE_TOOLS_SUCCESS:
        return {
          ...state,
          readToolsStatus: false,
          createToolsStatus: false,
          tools: action.payload.data.result,
        };
      case LOAD_RESOURCE_TOOLS_FAIL:
        return {
          ...state,
          readToolsStatus: false,
        };

      case LOAD_RESOURCE_CONSUMABLES:
        return { ...state, readConsumableStatus: true, consumables: [] };
      case LOAD_RESOURCE_CONSUMABLES_SUCCESS:
        return {
          ...state,
          readConsumableStatus: false,
          createConsumableStatus: false,
          consumables: action.payload.data.result,
        };
      case LOAD_RESOURCE_CONSUMABLES_FAIL:
        return {
          ...state,
          readConsumableStatus: false,
        };

      case LOAD_RESOURCE_LABOUR:
        return { ...state, readLabourStatus: true, labours: [] };
      case LOAD_RESOURCE_LABOUR_SUCCESS:
        return {
          ...state,
          readLabourStatus: false,
          createLabourStatus: false,
          labours: action.payload.data.result,
        };
      case LOAD_RESOURCE_LABOUR_FAIL:
        return {
          ...state,
          readLabourStatus: false,
        };

      case DELETE_RESOURCE:
        return { ...state };
      case DELETE_RESOURCE_SUCCESS:
        return { ...state };
      case DELETE_RESOURCE_FAIL:
        return { ...state };
      case CREATE_PS_RESOURCE:
        UprinceLogger.log('CREATE_PS_RESOURCE');
        return { ...state };
      case CREATE_PS_RESOURCE_SUCCESS:
        UprinceLogger.log('CREATE_PS_RESOURCE_SUCCESS');
        return { ...state, isChange: true };
      case CREATE_PS_RESOURCE_FAIL:
        UprinceLogger.log('CREATE_PS_RESOURCE_FAIL');
        return { ...state };

      case APPROVED_PS:
        return { ...state, showProgressBar: true };
      case APPROVED_PS_SUCCESS:
        return {
          ...state,
          isChange: true,
          showProgressBar: false,
        };
      case APPROVED_PS_FAIL:
        return {
          ...state,
          showProgressBar: false,
        };

      case APPROVED_PS_CU:
        return { ...state, showProgressBar: true };
      case APPROVED_PS_CU_SUCCESS:
        return {
          ...state,
          isChange: true,
          showProgressBar: false,
        };
      case APPROVED_PS_CU_FAIL:
        return {
          ...state,
          showProgressBar: false,
        };
      case LOAD_VAT_LIST:
        return { ...state, vatList: [] };
      case LOAD_VAT_LIST_SUCCESS:
        return {
          ...state,
          vatList: formattedPayloadToDropdown(action.payload.data.result.sort((a: any, b: any) => {
            let nameA = a.name; // ignore upper and lowercase
            let nameB = b.name;
            nameA = nameA.replace('%', '').padStart(2, 0);
            nameB = nameB.replace('%', '').padStart(2, 0);
            if (nameA < nameB) {
              return -1;
            }
            if (nameA > nameB) {
              return 1;
            }
            return 0;
          })),
        };
      case LOAD_VAT_LIST_FAIL:
        return {
          ...state,
          vatList: [],
        };


      default:
        return state;
    }
  }
}

export const readPSShortCutPaneData = () => {
  return {
    types: [
      LOAD_PS_SHORTCUTPANE,
      LOAD_PS_SHORTCUTPANE_SUCCESS,
      LOAD_PS_SHORTCUTPANE_FAIL,
    ],
    payload: { request: { url: READ_PS_SHORTCUT_PANE_EP } },
  };
};

export const readPSDropDownData = (projectSequenceCode: string | null) => {
  return {
    types: [
      LOAD_PS_DROPDOWN,
      LOAD_PS_DROPDOWN_SUCCESS,
      LOAD_PS_DROPDOWN_FAIL,
    ],
    payload: {
      request: {
        method: 'POST',
        data: { projectSequenceCode: projectSequenceCode },
        url: READ_PS_DROPDOWN_EP,
      },
    },
  };
};

export const readCostListData = (filter: CostFilter) => {
  return {
    types: [
      LOAD_COST_LIST,
      LOAD_COST_LIST_SUCCESS,
      LOAD_COST_LIST_FAIL,
    ],
    payload: {
      request: {
        method: 'POST',
        url: READ_COST_LIST_EP,
        data: filter,
      },
    },
  };
};

export const filterPSListPaneData = (filter: PSListPaneFilter) => {
  if (!filter.sorter.attribute) {
    filter.sorter.attribute = 'title';
    filter.sorter.order = 'desc';
  }
  return {
    types: [
      LOAD_PS_LSITPANE,
      LOAD_PS_LSITPANE_SUCCESS,
      LOAD_PS_LSITPANE_FAIL,
    ],
    payload: {
      request: {
        method: 'POST',
        url: READ_PS_LIST_EP,
        data: filter,
      },
    },
  };
};


export const readByPSId = (id: string, token: any) => {
  return {
    types: [LOAD_PS_BY_ID, LOAD_PS_BY_ID_SUCCESS, LOAD_PS_BY_ID_FAIL],
    payload: { request: { url: READ_PS_BY_ID_EP + id }, cancelToken: token },
  };
};

export const resetIsChange = () => {
  return { type: RESET_PS_IS_CHANGE };
};

export const saveFormData = (formData: ProgressStatement) => {
  return {
    types: [SAVE_PS, SAVE_PS_SUCCESS, SAVE_PS_FAIL],
    payload: {
      request: {
        url: PS_SAVE_EP,
        method: 'POST',
        data: formData,
      },
    },
  };
};

export const removeprojectcost = (formData: any) => {
  return {
    types: [REMOVE_PC, REMOVE_PC_SUCCESS, REMOVE_PC_FAIL],
    payload: {
      request: {
        url: REMOVE_PROJECTCOST,
        method: 'POST',
        data: formData,
      },
    },
  };
};
export const setUid = () => {
  return { type: SET_PS_UID };
};

export const clearFormData = () => {
  return { type: CLEAR_PS_FORM_DATA };
};


export const readPriceList = (projectSequenceCode: string | null) => {
  return {
    types: [READ_PRICE_LIST, READ_PRICE_LIST_SUCCESS, READ_PRICE_LIST_FAIL],
    payload: {
      request: {
        method: 'POST',
        data: { projectSequenceCode: projectSequenceCode },
        url: PRICE_LIST_READ_EP,
      },
    },
  };
};

export const savePriceList = (data: any) => {
  return {
    types: [SAVE_PRICE_LIST, SAVE_PRICE_LIST_SUCCESS, SAVE_PRICE_LIST_FAIL],
    payload: {
      request: {
        url: PRICE_LIST_SAVE_EP,
        method: 'POST',
        data: data,
      },
    },
  };
};

export const saveResource = (data: any) => {
  return {
    types: [SAVE_RESOURCE, SAVE_RESOURCE_SUCCESS, SAVE_RESOURCE_FAIL],
    payload: {
      request: {
        url: RESOURCE_SAVE_EP,
        method: 'POST',
        data: data,
      },
    },
  };
};


export const readPsMaterialsData = () => {
  return {
    types: [LOAD_RESOURCE_MATERIALS, LOAD_RESOURCE_MATERIALS_SUCCESS, LOAD_RESOURCE_MATERIALS_FAIL],
    payload: { request: { url: PS_RESOURCE_MATERIALS_READ_EP } },
  };
};

export const readPsToolsData = () => {
  return {
    types: [LOAD_RESOURCE_TOOLS, LOAD_RESOURCE_TOOLS_SUCCESS, LOAD_RESOURCE_TOOLS_FAIL],
    payload: { request: { url: PS_RESOURCE_TOOLS_READ_EP } },
  };
};

export const readPsConsumableData = () => {
  return {
    types: [LOAD_RESOURCE_CONSUMABLES, LOAD_RESOURCE_CONSUMABLES_SUCCESS, LOAD_RESOURCE_CONSUMABLES_FAIL],
    payload: { request: { url: PS_RESOURCE_CONSUMABLES_READ_EP } },
  };
};

export const readVATList = () => {
  return {
    types: [LOAD_VAT_LIST, LOAD_VAT_LIST_SUCCESS, LOAD_VAT_LIST_FAIL],
    payload: { request: { url: GET_VAT_EP } },
  };
};


export const readPsLaboursData = () => {
  return {
    types: [LOAD_RESOURCE_LABOUR, LOAD_RESOURCE_LABOUR_SUCCESS, LOAD_RESOURCE_LABOUR_FAIL],
    payload: { request: { url: PS_RESOURCE_LABOUR_READ_EP } },
  };
};

export const deletePriceList = (data: any) => {
  return {
    types: [DELETE_RESOURCE, DELETE_RESOURCE_SUCCESS, DELETE_RESOURCE_FAIL],
    payload: {
      request: {
        url: RESOURCE_DELETE_EP,
        method: 'DELETE',
        data: data,
      },
    },
  };
};

export const createPSResource = (data: any) => {
  return {
    types: [CREATE_PS_RESOURCE, CREATE_PS_RESOURCE_SUCCESS, CREATE_PS_RESOURCE_FAIL],
    payload: {
      request: {
        url: CREATE_PS_RESOURCE_EP,
        method: 'POST',
        data: data,
      },
    },
  };
};

export const approvedPs = (id: string) => {
  return {
    types: [APPROVED_PS, APPROVED_PS_SUCCESS, APPROVED_PS_FAIL],
    payload: {
      request: {
        url: APPROVED_PS_EP + id,
        method: 'PUT',
      },
    },
  };
};
export const approvedPsCu = (id: string) => {
  return {
    types: [APPROVED_PS_CU, APPROVED_PS_CU_SUCCESS, APPROVED_PS_CU_FAIL],
    payload: {
      request: {
        url: APPROVED_PS_CU_EP + id,
        method: 'PUT',
      },
    },
  };
};

export const saveVAT = (data: { id: string, vatId: string }) => {
  return {
    types: [SAVE_VAT, SAVE_VAT_SUCCESS, SAVE_VAT_FAIL],
    payload: {
      request: {
        url: SAVE_VAT_EP,
        method: 'POST',
        data: data,
      },
    },
  };
};

const formattedPSList = (result: any) => {
  if (result) {
    const data = result.map((item: ListPaneItem) => {
      return {
        ...item,
        status: item.status ? item.status : '-',
        project: item.project ? item.project : '-',
        totalAmount: item.totalAmount ? parseFloat(item.totalAmount).toFixed(2) : '0',
        title: item.title ? item.title : '-',
        date: item.date ? format24hDateTime(item.date) : '-',
      };
    });
    return data;
  }
  return [];
};

export const formattedCostList = (result: any) => {
  if (result) {
    const data = result.map((item: any) => {
      return {
        ...item,
        costMou: item.costMou ? parseFloat(item.costMou).toFixed(2) : parseFloat('0').toFixed(2),
        totalCost: item.totalCost ? parseFloat(item.totalCost).toFixed(2) : parseFloat('0').toFixed(2),
        date: item.date ? formatwithoutTime(item.date) : '-',
        isPlannedResource: item.isPlannedResource ? i18n.t('planned') : i18n.t('extra'),
      };
    });
    return data;
  }
  return [];
};

const formattedPayloadToDropdown = (response: any) => {
  if (response) {
    const data = response.map((item: any) => {
      return {
        key: item.id,
        text: item.name,
      };
    });
    return data;
  }
  return [];
};
