import React, { useEffect, useRef } from 'react';
import { Grid, GridProps } from '../grid/grid';
import { Calendar, CalendarProps } from '../calendar/calendar';
import { TaskGanttContent, TaskGanttContentProps } from './task-gantt-content';
import styles from './gantt.module.css';
import { ChartType } from '../../types/public-types';
import { useSelector } from 'react-redux';
import { isCuProjectPlanning, isPlanning } from '../../../util';

export type TaskGanttProps = {
  gridProps: GridProps;
  calendarProps: CalendarProps;
  barProps: TaskGanttContentProps;
  ganttHeight: number;
  scrollY: number;
  scrollX: number;
  chartType: ChartType;
  viewMode: string;
};
export const TaskGantt: React.FC<TaskGanttProps> = ({
                                                      gridProps,
                                                      calendarProps,
                                                      barProps,
                                                      ganttHeight,
                                                      scrollY,
                                                      scrollX,
                                                      chartType,
                                                      viewMode,
                                                    }) => {
  const ganttSVGRef = useRef<SVGSVGElement>(null);
  const horizontalContainerRef = useRef<HTMLDivElement>(null);
  const verticalGanttContainerRef = useRef<HTMLDivElement>(null);
  const newBarProps = { ...barProps, chartType, svg: ganttSVGRef };
  const ppbHeight = useSelector((state: any) =>state.vp.projectPlanningBottomHeight);
  const ltpbHeight = useSelector((state: any) =>state.vp.longTermPlanningBottomHeight);

  useEffect(() => {
    if (horizontalContainerRef.current) {
      horizontalContainerRef.current.scrollTop = scrollY;
    }
  }, [scrollY]);

  useEffect(() => {
    if (verticalGanttContainerRef.current) {
      verticalGanttContainerRef.current.scrollLeft = scrollX;
    }
  }, [scrollX]);

  const getGanttChartHeight = () => {
    if(isPlanning()){
      return ppbHeight?ppbHeight-100:screen.height-436;
    } else if(isCuProjectPlanning()){
      return ltpbHeight?ltpbHeight-100:screen.height-436;
    }    
  }

  return (
    <div
      className={ styles.ganttVerticalContainer}
      ref={verticalGanttContainerRef}
      dir="ltr"
      style={{width: '100%', overflowX:'scroll', height: getGanttChartHeight()}}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={gridProps.svgWidth}
        height={calendarProps.headerHeight}
        fontFamily={barProps.fontFamily}
        style={{overflow: 'scroll !important'}}
      >
        <Calendar {...calendarProps} />
      </svg>
      <div
        ref={horizontalContainerRef}
        //className={styles.horizontalContainer}
        className={ "gantt4k" }
        style={
          ganttHeight
            ? { height: ganttHeight, width: gridProps.svgWidth }
            : { width: gridProps.svgWidth }
        }
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={gridProps.svgWidth}
          height={(barProps.rowHeight * barProps.tasks.length)}
          fontFamily={barProps.fontFamily}
          ref={ganttSVGRef}
        >
          <Grid {...gridProps} />
          <TaskGanttContent {...newBarProps} />
        </svg>
      </div>
    </div>
  );
};
