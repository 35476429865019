import {
  ActionButton,
  getTheme,
  IBasePickerSuggestionsProps,
  IIconProps,
  IPersonaProps,
  IStackItemStyles,
  IStackStyles,
  IStackTokens,
  Label,
  Link,
  mergeStyleSets,
  NormalPeoplePicker,
  Persona,
  Separator,
  Stack,
  ValidationState,
} from '@fluentui/react';
import { createForm } from 'final-form';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { Field, Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { uPrinceTheme } from '../../../../theme';
import { addUserProject } from '../../../reducers/cuProjectReducer';
import {
  deleteTeamMembers,
  getProjectDefinition,
  saveFormData,
  saveProjectStateAttr,
  updateProjectDefinition,
  createTeamSite,
} from '../../../reducers/projectReducer';
import DataGridWithModal, {
  ModalGridContext,
} from '../../../shared/dataGridModal/component';
import {
  DropDownAdaptater,
  TextFieldFF,
} from '../../../shared/fluentFinalForm';
import PeoplePicker from '../../../shared/fluentFinalForm/PeoplePicker';
import HistoryBar from '../../../shared/historyBar/historyBar';
import AzureLocationComponent from '../../../shared/location/Azure/component';
import { getContractingUnit } from '../../../shared/util';
import { Project, projectInitialFormData } from '../../../types/project';
import ProjectClassificationCard from './classification/component';
import ProjectFinanceCard from './finance/component';
import TeamsModalContent from './teams/component';
import ProjectTimeCard from './time/component';
import { messageService } from '../../../services/messageService';
import i18n from '../../../../i18n';
import { mergeStyles } from '@fluentui/react/lib/Styling';
import client from '../../../api';
import { AddressBookItem } from '../../../types/addressBookItem';
import { savePbsStateAttr } from '../../../reducers/projectBreakdownReducer';

const theme = getTheme();

const stackStyles: IStackStyles = { root: { padding: 0, marginBottom: 10 } };

const stackItemStyles: IStackItemStyles = {
  root: {
    display: 'flex',
    height: 50,
    width: 0,
    flexGrow: 1,
  },
};

const stackTokens: IStackTokens = {
  childrenGap: 10,
  padding: 10,
};

const itemAlignmentsStackTokens: IStackTokens = {
  childrenGap: 3,
  padding: 10,
};

const iconButtonStyles = {
  root: { color: uPrinceTheme.palette.themePrimary },
  rootHovered: { color: theme.palette.neutralDark },
};

const stackFooterBarItemStyles: IStackItemStyles = { root: { height: 45 } };

const saveIcon: IIconProps = { iconName: 'Save', styles: iconButtonStyles };

const classNames = mergeStyleSets({
  wrapper: {
    // height: '100vh',
    position: 'relative',
    maxHeight: 'inherit',
  },
  pane: { maxWidth: 400 },
  textContent: { padding: '15px 10px' },
  actionIcon: {
    padding: 5,
    cursor: 'pointer',
  },
  labelRightWrapper: {
    textAlign: 'end',
    selectors: { '@media(max-width: 1100px)': { textAlign: 'start' } },
  },
  actionButton: {
    color: uPrinceTheme.palette.themePrimary,
    cursor: 'pointer',
  },
  labelRightIcon: {
    display: 'inline-block',
    verticalAlign: 'bottom',
    paddingBottom: 2,
    paddingRight: 5,
  },
  fullWidth: { width: '100%' },
  halfWidth: { width: '49%' },
});

const DocumentPaneComponent = (props: {
  submit: any;
  saveFormData: any;
  uid: any;
  formValues: any;
  formData: any;
  // isExpand: boolean,
  setForm: (form: any) => void;
  isClear: boolean;
}) => {
  const [state, setstate] = useState<Project | null>(null);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const PM = '266a5f47-3489-484b-8dae-e4468c5329dn3';
  const CPC = '910b7af0-b132-4951-a2dc-6ab82d4cd40d';
  const SM = 'yyyyyyy-a513-45e0-a431-170dbd4yyyy';

  const {
    projectType,
    projectManagementLevel,
    projectToleranceState,
    projectTemplate,
    projectScopeStatus,
    projectState,
    isClear,
    selectedProject,
    projectID,
    showProgressBar,
    projectLanguage,
    updateProjectList,
    ledgerDropDown,
    isDetailsLoaded,
    isProjectLoaded,
  }: any = useSelector((projectState: any) => projectState.project);

  const { contractingUnits }: any = useSelector(
    (cuProjectState: any) => cuProjectState.cuProject
  );

  const getStatus = (state: string) => {
    if (state === '3') {
      return t('invitationPending');
    } else if (state === '1') {
      return t('invited');
    } else if (state === '2') {
      return t('invited');
    }
    return t('acccepted');
  };

  useEffect(() => {
    if (selectedProject?.id && selectedProject?.id !== '0') {
      dispatch(addUserProject(selectedProject?.id));
    }
  }, [selectedProject]);

  useEffect(() => {
    if (projectID && projectID?.projectId) {
      console.log('MMMSSSSSQSQQQQQQQSSSSS');
      dispatch(getProjectDefinition(projectID?.sequenceId));
      dispatch(createTeamSite({ project: projectID?.sequenceId }));
    }
    dispatch(saveProjectStateAttr('projectID', null));
  }, [projectID]);

  useEffect(() => {
    if (updateProjectList) {
      dispatch(getProjectDefinition(selectedProject?.sequenceCode));
    }
  }, [updateProjectList]);

  const rendercardFooter = (form: any) => {
    return (
      <div className={'card-footer'}>
        <Separator />
        <Stack styles={stackStyles} tokens={itemAlignmentsStackTokens}>
          <Stack.Item align="end" styles={stackFooterBarItemStyles}>
            <ActionButton
              className={classNames.actionButton}
              iconProps={saveIcon}
              allowDisabledFocus
              onClick={() => {
                // alert(JSON.stringify(values.purchaseOrderType, 0, 2));
                form.submit();
              }}
              // disabled={ (props.dropDownOptions.isApproved) }
            >
              <Label className={classNames.actionButton}>{t('save')}</Label>
            </ActionButton>
          </Stack.Item>
        </Stack>
      </div>
    );
  };

  const teamsColumns = [
    {
      key: 'column1',
      name: t('Name'),
      fieldName: 'cabPersonName',
      minWidth: 200,
      maxWidth: 300,
      isResizable: true,
      onRender: (item: any) => {
        return <span>{`${item.cabPersonName} (${item.companyName !== null ?item.companyName:''})`}</span>;
      },
    },
    {
      key: 'column2',
      name: t('role'),
      fieldName: 'roleName',
      minWidth: 200,
      maxWidth: 300,
      isResizable: true,
    },
    {
      key: 'column3',
      name: t('status'),
      fieldName: 'status',
      minWidth: 200,
      maxWidth: 300,
      isResizable: true,
      // eslint-disable-next-line react/display-name
      onRender: (item: any) => {
        return <span>{getStatus(item.status)}</span>;
      },
    },
  ];

  const onSubmit = async (values: any) => {
    dispatch(savePbsStateAttr('isPBSOpenFromVP', false));
    if (values.id) {
      dispatch(
        updateProjectDefinition({
          ...values,
          kpi: { ...values.kpi, projectId: values.id },
          projectFinance: { ...values.projectFinance, projectId: values.id },
          projectTime: { ...values.projectTime, projectId: values.id },
        })
      );
    } else {
      dispatch(saveFormData(values));
    }
  };

  const getSelectedContractingUnit = (): any[] => {
    if (contractingUnits && Array.isArray(contractingUnits)) {
      const cu = contractingUnits.filter(
        (unit) => unit.sequenceCode === getContractingUnit()
      );
      return cu;
    }
    return [];
  };

  const formRef = React.useRef(createForm({ onSubmit: onSubmit }));

  const getInitialFormValues = (formData2: any, formValue: any) => {
    if (location.pathname.split('/').pop() === 'new' && isClear) {
      return {
        ...projectInitialFormData,
        projectTeam: {
          id: null,
          contractingUnitId:
            Array.isArray(getSelectedContractingUnit()) &&
            getSelectedContractingUnit().length > 0
              ? getSelectedContractingUnit()[0]?.key
              : null,
          contractingUnitName:
            Array.isArray(getSelectedContractingUnit()) &&
            getSelectedContractingUnit().length > 0
              ? getSelectedContractingUnit()[0]?.name
              : null,
          projectId: null,
          teamRoleList: [],
        },
      };
    } else if (selectedProject && selectedProject?.id) {
      return {
        ...selectedProject,
        kpi: selectedProject.projectKpi,
        customerName: `${selectedProject.customerName} ${
          selectedProject.customerCompanyName
            ? `(${selectedProject.customerCompanyName})`
            : ''
        }`,
      };
    }
    return formValue;
  };

  useEffect(() => {
    setstate(props.formData);
  }, [props.formData]);

  useEffect(() => {
    formRef.current.resetFieldState('name');
  }, [selectedProject]);

  const addIcon: IIconProps = { iconName: 'Add' };
  const limitedSearchAdditionalProps: IBasePickerSuggestionsProps = {
    resultsFooter: (props: any) => (
      <ActionButton
        className={classNames.actionButton}
        disabled={false}
        iconProps={addIcon}
        allowDisabledFocus
        onClick={(event) => {
          messageService.sendMessage({ isOpenNameModal: true });
        }}
      >
        <Label className={classNames.actionButton}>
          {i18n.t('new') as any}
        </Label>
      </ActionButton>
    ),
    showRemoveButtons: false,
    searchingText: 'Searching...',
  };

  const newItem = mergeStyles({ padding: '1px' });
  const existingItem = mergeStyles({ padding: '10px' });
  const onRenderSuggestionsItem = (props: any, itemProps: any): JSX.Element => {
    if (props.isNewItem) {
      return (
        <div
          className={props.isNewItem ? newItem : existingItem}
          key={props.key}
        >
          <ActionButton iconProps={addIcon}>{props.text}</ActionButton>
        </div>
      );
    }
    return (
      <div className={props.isNewItem ? newItem : existingItem} key={props.key}>
        <Persona {...props} size={1} hidePersonaDetails={false} />
      </div>
    );
  };

  const getPersonByName = async (name: string) => {
    let response = await client.get(
      `CentralAddressBook/ReadCabPersonList/${name}`
    );
    return formatPersonResponse(response, name);
  };

  const formatPersonResponse = (response: any, name: any) => {
    //let data:ITag[]|any = [];
    let data: { key: string; text: string; isNewItem: boolean }[] = [];
    if (response && response.data && _.isArray(response.data.result)) {
      data = response.data.result.map((item: AddressBookItem) => {
        return {
          ...item,
          text: item.person
            ? `${item.person.fullName} ${
                item.company ? `(${item.company.name})` : ''
              }`
            : '',
          secondaryText: item.personCompany ? item.personCompany.jobRole : '',
          key: item.id,
          isNewItem: false,
        };
      });
      let obj = data.find(
        (o: any) => o.person.fullName.toLowerCase() === name.toLowerCase()
      );
      if (!obj) {
        data.unshift({ key: '', text: name, isNewItem: true });
      }
    } else {
      return [{ key: '', text: name, isNewItem: true }];
    }
    return data;
  };

  const onFilterPersonChanged = (
    filter: string,
    selectedItems?: IPersonaProps[] | undefined
  ) => {
    if (filter) {
      return getPersonByName(filter);
    } else {
      return [];
    }
  };

  const onPersonSelect = React.useCallback((item: any | undefined): any => {
    if (item) {
      console.log('onPersonSelect > ', item);
      if (item.isNewItem) {
        // messageService.sendMessage({
        //   isOpenNameModal: true,
        //   personName: item.text,
        // });
      } else {
        // props.onPersonSelect(item);
      }
      return item;
    }
    return null;
  }, []);

  const validateInput = (input: string): ValidationState => {
    if (input.indexOf('@') !== -1) {
      return ValidationState.valid;
    } else if (input.length > 1) {
      return ValidationState.warning;
    } else {
      return ValidationState.invalid;
    }
  };

  return (
    <div style={{ width: '100%' }}>
      <div className="proj-detail-block">
        <Form
          form={formRef.current}
          onSubmit={onSubmit}
          initialValues={getInitialFormValues(
            selectedProject,
            props.formValues
          )}
          mutators={{
            setGeneralLedger: (args, state, utils) => {
              const field = state.fields.generalLedgerId;
              field.change(args[0]);
            },
            setPMName: (args, state, utils) => {
              const field = state.fields.projectManagerName;
              field.change(args[0]);
            },
            setSMName: (args, state, utils) => {
              const field = state.fields.siteManagerName;
              field.change(args[0]);
            },
            setCustomerName: (args, state, utils) => {
              const field = state.fields.customerName;
              field.change(args[0]);
            },
          }}
          validate={(values) => {
            const errors: any = {};
            if (!values?.name && !showProgressBar) {
              errors.name = t('nameRequired');
            }
            return errors;
          }}
          render={({ handleSubmit, form, submitting, pristine, values }) => {
            props.submit(handleSubmit);
            props.setForm(form);
            return (
              <form onSubmit={handleSubmit} noValidate>
                <div className="document-pane-card">
                  <div className={'card-header'}>
                    <Link href="#" id="header">
                      <Label>1. {t('projectDefinition')}</Label>
                    </Link>
                  </div>
                  {values.sequenceCode && (
                    <Stack
                      horizontal
                      wrap
                      styles={stackStyles}
                      tokens={stackTokens}
                    >
                      <Stack.Item grow={6} styles={stackItemStyles}>
                        <div className={`${classNames.fullWidth}`}>
                          <Field
                            name="title"
                            component={TextFieldFF}
                            lable={t('title')}
                            disabled={true}
                          />
                        </div>
                      </Stack.Item>
                      <Stack.Item grow={6} styles={stackItemStyles}>
                        <div className={`${classNames.fullWidth}`}>
                          <Field
                            name="sequenceCode"
                            component={TextFieldFF}
                            lable={t('ID')}
                            disabled={true}
                          />
                        </div>
                      </Stack.Item>
                    </Stack>
                  )}

                  <Stack
                    horizontal
                    wrap
                    styles={stackStyles}
                    tokens={stackTokens}
                  >
                    <Stack.Item grow={6} styles={stackItemStyles}>
                      <div className={`${classNames.halfWidth}`}>
                        <Field
                          name="name"
                          component={TextFieldFF}
                          lable={t('name')}
                          required={true}
                        />
                      </div>
                    </Stack.Item>
                  </Stack>

                  <Stack
                    horizontal
                    wrap
                    styles={{ root: { padding: 0, marginBottom: 30 } }}
                    tokens={stackTokens}
                  >
                    <Stack.Item grow={6} styles={stackItemStyles}>
                      <div className={`${classNames.fullWidth}`}>
                        <Field
                          name="description"
                          component={TextFieldFF}
                          multiline
                          lable={t('description')}
                        />
                      </div>
                    </Stack.Item>
                  </Stack>

                  <div style={{ display: 'none' }}>
                    {/*hide for bm requirement */}
                    <Stack
                      horizontal
                      wrap
                      styles={stackStyles}
                      tokens={stackTokens}
                    >
                      <Stack.Item grow={6} styles={stackItemStyles}>
                        <div className={`${classNames.fullWidth}`}>
                          <Field
                            name="projectTypeId"
                            component={DropDownAdaptater}
                            options={projectType}
                            lable={t('type')}
                            placeholder={t('selectanoption')}
                            disabled={false}
                            required={false}
                          />
                        </div>
                      </Stack.Item>

                      <Stack.Item grow={6} styles={stackItemStyles}>
                        <div className={`${classNames.fullWidth}`}>
                          <Field
                            name="projectManagementLevelId"
                            component={DropDownAdaptater}
                            options={projectManagementLevel}
                            lable={t('managementLevel')}
                            placeholder={t('selectanoption')}
                            disabled={false}
                            required={false}
                          />
                        </div>
                      </Stack.Item>
                    </Stack>

                    <Stack
                      horizontal
                      wrap
                      styles={stackStyles}
                      tokens={stackTokens}
                    >
                      <Stack.Item grow={6} styles={stackItemStyles}>
                        <div className={`${classNames.fullWidth}`}>
                          <Field
                            name="projectToleranceStateId"
                            component={DropDownAdaptater}
                            options={projectToleranceState}
                            lable={t('toleranceState')}
                            placeholder={t('selectanoption')}
                            disabled={false}
                            required={false}
                          />
                        </div>
                      </Stack.Item>

                      <Stack.Item grow={6} styles={stackItemStyles}>
                        <div className={`${classNames.fullWidth}`}>
                          <Field
                            name="projectTemplateId"
                            component={DropDownAdaptater}
                            options={projectTemplate}
                            lable={t('template')}
                            placeholder={t('selectanoption')}
                            disabled={false}
                          />
                        </div>
                      </Stack.Item>
                    </Stack>
                  </div>
                  <Stack
                    horizontal
                    wrap
                    styles={stackStyles}
                    tokens={stackTokens}
                  >
                    <Stack.Item grow={6} styles={stackItemStyles}>
                      <div className={`${classNames.fullWidth}`}>
                        <Field
                          name="projectScopeStatusId"
                          component={DropDownAdaptater}
                          options={projectScopeStatus}
                          lable={t('scopeStatus')}
                          placeholder={t('selectanoption')}
                          disabled={false}
                          required={true}
                        />
                      </div>
                    </Stack.Item>

                    <Stack.Item grow={6} styles={stackItemStyles}>
                      <div className={`${classNames.fullWidth}`}>
                        <Field
                          name="language"
                          component={DropDownAdaptater}
                          options={projectLanguage}
                          lable={t('language')}
                          placeholder={t('language')}
                          disabled={false}
                        />
                      </div>
                    </Stack.Item>
                  </Stack>

                  <Stack
                    horizontal
                    wrap
                    styles={stackStyles}
                    tokens={stackTokens}
                  >
                    <Stack.Item grow={6} styles={stackItemStyles}>
                      <div className={`${classNames.fullWidth}`}>
                        <Field
                          name="projectStatus"
                          component={DropDownAdaptater}
                          options={projectState}
                          lable={t('projectStatus')}
                          placeholder={t('projectStatus')}
                          disabled={false}
                        />
                      </div>
                    </Stack.Item>

                    <Stack.Item grow={6} styles={stackItemStyles}>
                      <div className={`${classNames.fullWidth}`}>
                        <Field name="projectManagerId">
                          {({ input, meta }) => (
                            <PeoplePicker
                              label={t('projectManager')}
                              isDisabled={false}
                              selectedItem={
                                values?.projectManagerId
                                  ? [
                                      {
                                        key: values?.projectManagerId,
                                        text: values?.projectManagerName,
                                      },
                                    ]
                                  : []
                              }
                              onChange={(items: any) => {
                                if (
                                  items &&
                                  _.isArray(items) &&
                                  items.length === 0
                                ) {
                                  input.onChange(null);
                                }
                                if (!items) {
                                  input.onChange(null);
                                }

                                return items ? items : [];
                              }}
                              onPersonSelected={(item: any) => {
                                input.onChange(item.cabPersonId);
                                form.mutators.setPMName(item?.text);
                                return item;
                              }}
                            />
                          )}
                        </Field>
                        <Field
                          name="projectManagerName"
                          component={'input'}
                          type={'text'}
                          disabled={true}
                          hidden
                        />
                      </div>
                    </Stack.Item>
                  </Stack>

                  <Stack
                    horizontal
                    wrap
                    styles={stackStyles}
                    tokens={stackTokens}
                  >
                    <Stack.Item grow={6} styles={stackItemStyles}>
                      <div className={`${classNames.fullWidth}`}>
                        <Field name="customerId">
                          {({ input, meta }) => (
                            <div>
                              <Label required={false}>{t('customer')}</Label>
                              <NormalPeoplePicker
                                // eslint-disable-next-line react/jsx-no-bind
                                selectedItems={
                                  values?.customerId
                                    ? [
                                        {
                                          key: values?.customerId,
                                          text: values?.customerName, //TODO: set value
                                        },
                                      ]
                                    : []
                                }
                                onResolveSuggestions={onFilterPersonChanged}
                                // eslint-disable-next-line react/jsx-no-bind
                                onChange={(items: any) => {
                                  if (
                                    items &&
                                    _.isArray(items) &&
                                    items.length === 0
                                  ) {
                                    input.onChange(null);
                                  }
                                  if (!items) {
                                    input.onChange(null);
                                  }
                                  return items ? items : [];
                                }}
                                onItemSelected={(item: any) => {
                                  if (item) {
                                    // const selectedKey = `/CU/${contractingUnit}/project/${resource?.resourceId}/project-planning`;
                                    if (item.isNewItem) {
                                      window.open('/address-book/new');
                                    } else {
                                      // item.person ? `${item.person.fullName} ${item.company.name ? `(${item.company.name})` : ''}` : '',
                                      input.onChange(item.person.id);
                                      form.mutators.setCustomerName(
                                        `${item.person.fullName} ${
                                          item.company
                                            ? `(${item.company.name})`
                                            : ''
                                        }`
                                      );
                                    }
                                    return item;
                                  }
                                  return null;
                                }}
                                pickerSuggestionsProps={
                                  limitedSearchAdditionalProps
                                }
                                className={'ms-PeoplePicker'}
                                key={'normal'}
                                itemLimit={1}
                                // eslint-disable-next-line react/jsx-no-bind
                                onValidateInput={validateInput}
                                removeButtonAriaLabel={'Remove'}
                                inputProps={{
                                  onBlur: () => console.log('onBlur called'),
                                  onFocus: () => console.log('onFocus called'),
                                  'aria-label': 'People Picker',
                                }}
                                onInputChange={(input: string) => {
                                  return input;
                                }}
                                resolveDelay={300}
                                disabled={false}
                                onRenderSuggestionsItem={
                                  onRenderSuggestionsItem
                                }
                              />
                              {/*{meta.error && (*/}
                              {/*  <Text styles={{root: {color: '#a4262c', fontSize: 12}}}>*/}
                              {/*      {meta.error}*/}
                              {/*  </Text>*/}
                              {/*)}*/}
                            </div>
                          )}
                        </Field>
                        <Field
                          name="customerName"
                          component={'input'}
                          type={'text'}
                          disabled={true}
                          hidden
                        />
                      </div>
                    </Stack.Item>

                    <Stack.Item grow={6} styles={stackItemStyles}>
                      <div className={`${classNames.fullWidth}`}>
                        <Field name="siteManagerId">
                          {({ input, meta }) => (
                            <PeoplePicker
                              label={t('siteManager')}
                              isDisabled={false}
                              selectedItem={
                                values?.siteManagerId
                                  ? [
                                      {
                                        key: values?.siteManagerId,
                                        text: values?.siteManagerName,
                                      },
                                    ]
                                  : []
                              }
                              onChange={(items: any) => {
                                if (
                                  items &&
                                  _.isArray(items) &&
                                  items.length === 0
                                ) {
                                  input.onChange(null);
                                }
                                if (!items) {
                                  input.onChange(null);
                                }

                                return items ? items : [];
                              }}
                              onPersonSelected={(item: any) => {
                                input.onChange(item.cabPersonId);
                                form.mutators.setSMName(item?.text);
                                return item;
                              }}
                            />
                          )}
                        </Field>
                        <Field
                          name="siteManagerName"
                          component={'input'}
                          type={'text'}
                          disabled={true}
                          hidden
                        />
                      </div>
                    </Stack.Item>
                  </Stack>

                  {rendercardFooter(form)}
                </div>

                <Field name="projectClassification">
                  {({ input, meta }) => (
                    <ProjectClassificationCard
                      disabled={false}
                      lable={`2. ${t('projectClassification')}`}
                      classification={values.projectClassification}
                      onChange={(item: any) => {
                        input.onChange(item);
                      }}
                      footer={rendercardFooter(form)}
                    />
                  )}
                </Field>

                <div className="document-pane-card">
                  <div className={'card-header'}>
                    <Link href="#" id="project-address">
                      <Label>3. {t('address')}</Label>
                    </Link>
                  </div>
                  <Stack
                    horizontal
                    wrap
                    styles={stackStyles}
                    tokens={stackTokens}
                  >
                    <div className={`${classNames.fullWidth}`}>
                      <div style={{ zIndex: 10000000 }}>
                        <Field name="location">
                          {({ input, meta }) => (
                            <AzureLocationComponent
                              setLocationAddress={(address: any) => {
                                // props.setLocationAddress(address.position, address);
                                input.onChange(address);
                              }}
                              // position={ AzureLocationComponent.getPosition(values.location) }
                              address={AzureLocationComponent.getAddress(
                                values.location
                              )}
                              disabled={false}
                              azureLocation={values.location}
                              lable={t('coordinates')}
                              addressLable={t('address')}
                              required={false}
                              // errorMessage={ null}
                            />
                          )}
                        </Field>
                        {rendercardFooter(form)}
                      </div>
                    </div>
                  </Stack>
                </div>

                <div className="document-pane-card">
                  <div className={'card-header'}>
                    <Link href="#" id="project-team">
                      <Label>4. {t('Team')}</Label>
                    </Link>
                  </div>

                  <div className={`${classNames.halfWidth}`}>
                    <Field
                      name="projectTeam.contractingUnitName"
                      component={TextFieldFF}
                      lable={t('contractingUnit')}
                      disabled={true}
                    />
                  </div>

                  <Field name="projectTeam">
                    {({ input, meta }) => (
                      <DataGridWithModal
                        dataList={
                          values.projectTeam?.teamRoleList &&
                          Array.isArray(values.projectTeam.teamRoleList)
                            ? values.projectTeam.teamRoleList
                            : []
                        }
                        modalTitle={t('addTeamMember')}
                        deleteDataGrid={async (selection: any) => {
                          const selectedList = selection.map((item: any) => {
                            return item.id;
                          });
                          Promise.all([
                            dispatch(deleteTeamMembers(selectedList)),
                          ]).then(() => {
                            dispatch(
                              getProjectDefinition(
                                selectedProject?.sequenceCode
                              )
                            );
                          });
                          // const res:any = await dispatch(deleteTeamMembers(selectedList));
                          // if (res.payload?.status === 200) {
                          //     dispatch(getProjectDefinition(getProject()));
                          // }
                        }}
                        title={' '}
                        readOnly={false}
                        columns={teamsColumns}
                        isLoaded={false}
                        canSelectItem={(item: any) => {
                          if (
                            item.roleId === PM ||
                            item.roleId === CPC ||
                            item.roleId === SM
                          ) {
                            return false;
                          } else {
                            return true;
                          }
                        }}
                        onItemInvoked={(item: any) => {
                          if (item.roleId === PM || item.roleId === CPC) {
                            return false;
                          } else {
                            return true;
                          }
                        }}
                      >
                        <ModalGridContext.Consumer>
                          {(modalProps: any) => (
                            <TeamsModalContent
                              teamMember={modalProps.editItem}
                              dataList={modalProps.dataList}
                              disabled={false}
                              onChange={(item: any) => {
                                if (
                                  values.projectTeam &&
                                  values.projectTeam?.teamRoleList &&
                                  Array.isArray(
                                    values.projectTeam?.teamRoleList
                                  )
                                ) {
                                  const members: any[] = [];
                                  const currentList: any[] =
                                    values.projectTeam?.teamRoleList;
                                  let team = [];
                                  if (item.id) {
                                    const index = currentList.findIndex(
                                      (object) => {
                                        return object.id === item.id;
                                      }
                                    );
                                    if (index < currentList.length) {
                                      currentList[index] = item;
                                    }
                                    team = {
                                      ...values.projectTeam,
                                      projectId: null,
                                      teamRoleList: currentList,
                                    };
                                    input.onChange(team);
                                    modalProps.updateEditItem(item);
                                  } else {
                                    members.push(item);
                                    currentList.forEach((teamItem: any) => {
                                      members.push(teamItem);
                                    });
                                    team = {
                                      ...values.projectTeam,
                                      projectId: null,
                                      teamRoleList: members,
                                    };
                                    input.onChange(team);
                                  }
                                  if (values.id) {
                                    dispatch(
                                      updateProjectDefinition({
                                        ...values,
                                        projectTeam: team,
                                        kpi: {
                                          ...values.projectKpi,
                                          projectId: values.id,
                                        },
                                        projectFinance: {
                                          ...values.projectFinance,
                                          projectId: values.id,
                                        },
                                        projectTime: {
                                          ...values.projectTime,
                                          projectId: values.id,
                                        },
                                      })
                                    );
                                  }
                                }
                                modalProps.closeModal();
                              }}
                            />
                          )}
                        </ModalGridContext.Consumer>
                      </DataGridWithModal>
                    )}
                  </Field>
                  {rendercardFooter(form)}
                </div>

                <Field name="projectFinance">
                  {({ input, meta }) => (
                    <ProjectFinanceCard
                      disabled={false}
                      finance={values.projectFinance}
                      onChange={(item: any) => {
                        input.onChange(item);
                      }}
                      footer={rendercardFooter(form)}
                    />
                  )}
                </Field>

                <Field name="projectTime">
                  {({ input, meta }) => (
                    <ProjectTimeCard
                      disabled={false}
                      projectTime={values.projectTime}
                      onChange={(item: any) => {
                        input.onChange(item);
                      }}
                      footer={rendercardFooter(form)}
                    />
                  )}
                </Field>

                {/*<Field name="kpi">*/}
                {/*{ ({ input, meta }) => (*/}
                {/*<ProjectKPIs*/}
                {/*kpi={ values.kpi }*/}
                {/*disabled={ false }*/}
                {/*onChange={ (item:any) => {*/}
                {/*input.onChange(item);*/}
                {/*} }*/}
                {/*footer={ rendercardFooter(form) }*/}
                {/*/>*/}
                {/*) }*/}
                {/*</Field>*/}

                {/*<Field name="projectCostConversionCreateDto">*/}
                {/*{ ({ input, meta }) => (*/}
                {/*<ProjectCostCard*/}
                {/*costConvertion={ values.projectCostConversionCreateDto }*/}
                {/*ledgerId={ values.generalLedgerId }*/}
                {/*disabled={ false }*/}
                {/*onChange={ (item:any) => {*/}
                {/*const { ledgerId, ...result } = item;*/}
                {/*input.onChange({ ...result});*/}
                {/*if (ledgerId) {*/}
                {/*form.mutators.setGeneralLedger(ledgerId);*/}
                {/*}*/}
                {/*} }*/}
                {/*footer={ rendercardFooter(form) }*/}
                {/*/>*/}
                {/*) }*/}
                {/*</Field>*/}
                <Field
                  name="generalLedgerId"
                  component={'input'}
                  type={'text'}
                  disabled={true}
                  hidden
                />

                {values.projectHistory &&
                  values.projectHistory.createdByUser && (
                    <div className="proj-detail-block" id="7">
                      <div className="document-pane-card">
                        <div className="marginTop marginBottom">
                          <Link href="#" id="history">
                            <Label>7. {t('history')} </Label>
                          </Link>

                          <HistoryBar
                            createdByUser={
                              values.projectHistory.createdByUser
                                ? values.projectHistory.createdByUser
                                : ''
                            }
                            updatedByUser={
                              values.projectHistory.updatedByUser
                                ? values.projectHistory.updatedByUser
                                : ''
                            }
                            createdDateTime={
                              values.projectHistory.createdDateTime
                                ? values.projectHistory.createdDateTime
                                : ''
                            }
                            updatedDateTime={
                              values.projectHistory.updatedDateTime
                                ? values.projectHistory.updatedDateTime
                                : ''
                            }
                          />
                        </div>
                      </div>
                    </div>
                  )}

                {/* <pre>{ JSON.stringify(values, 0, 2) }</pre>   */}
              </form>
            );
          }}
        />
      </div>
    </div>
  );
};
export default DocumentPaneComponent;
