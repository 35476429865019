import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {
    ActionButton,
    DatePicker,
    defaultCalendarStrings,
    Dropdown,
    IDropdownOption,
    IStackStyles,
    IStackTokens,
    mergeStyleSets,
    Pivot,
    PivotItem,
    PrimaryButton,
} from '@fluentui/react';
import {DpViewMode, ProjectPlanResourceType} from '../../../types/myDayPlanning';
import {generateCIAW, generateRFQ, saveProjectDayPlanningStateAttr,} from '../../../reducers/projectDayPlanningReducer';
import { formatDateTimeToISOString, isMyEnvironment, stringDateToDateObject } from '../../../shared/util';
import {store} from '../../../../index';
import i18n from '../../../../i18n';
import PlanboardFilter from './commandBarLeft/pdpFilters';
import PersonFilters from '../../myDayPlanning/bottomLIstPane/filters';

const classNames = mergeStyleSets({
    wrapper: {
        display: 'block',
        maxWidth: '200px',
        minWidth: '180px'
    },
    aligment: {
        margin: '2px 0 0 5px'
    },
    fullWidth: {width: '100%'},
    halfWidth: {width: '49%'},
    btnWrapper: {
        display: 'block',
        maxWidth: '200px'
    },
});

const stackStyles: IStackStyles = { root: { padding: 0, height: 42 } };

const stackTokens: IStackTokens = { childrenGap: 10 };

    const CommandBarLeft = (props: { disable: boolean, isTopPane?: boolean, onSelectDate?: any, refresh?: any }) => {
        const { t } = useTranslation();
        const dispatch = useDispatch();

        const viewModeDp: IDropdownOption[] = [
            {
                key: DpViewMode.QuarterHour,
                text: i18n.t('quarterPast'),
            },
            {
                key: DpViewMode.HalfHour,
                text: i18n.t('halfAnHour'),
            },
            {
                key: DpViewMode.OneHour,
                text: i18n.t('oneHour'),
            },
            {
                key: DpViewMode.TwoHour,
                text: i18n.t('twoHours'),
            },
            {
                key: DpViewMode.FourHour,
                text: i18n.t('fourHours'),
            },
            {
                key: DpViewMode.SixHour,
                text: i18n.t('sixHours'),
            },
            {
                key: DpViewMode.EightHour,
                text: i18n.t('eightHours'),
            },
            {
                key: DpViewMode.TwelveHour,
                text: i18n.t('twelveHours'),
            }
        ];
        i18n.t('load');
        i18n.t('travel');
        i18n.t('work');
        const {
            userInfo
        } = useSelector(
          (state: any) => state.uprince
        );

        const {
            selectedResourceType,
            projectDpViewMode,
            selectedCu,
            selectedBu,
            pmolList,
            ppbPmolTypeId,
            ppbSiteManagerId,
        } = useSelector(
          (state: any) => state.projectDPlan
        );

        const {
            planboardPersonId,
            planboardPersonName,
            selectedDate
        } = useSelector(
          (state: any) => state.mydPlan
        );

        const {
            pmolDropDowns
        } = useSelector(
          (state: any) => state.pmol
        );

        const contractingUnit = selectedCu ? selectedCu?.sequenceCode : userInfo?.contractingUnit?.sequnceCode;
        const [projectSequenceCode, setProjectSequenceCode] = useState<any>(null);
        const [pmolType, setPmolType] = useState<any>(null);

    //     useEffect(() => {
    //         if (projectSequenceCode) {
    //         dispatch(filterProjectDayPlanningProjectList({
    //             executionDate: formatDateTimeToISOString(selectedDate),
    //             contractingUnit: [contractingUnit],
    //             buId: selectedBu ?? null,
    //             cabPersonId: planboardPersonId ? planboardPersonId : null,
    //             projectSequenceCode: projectSequenceCode.value,
    //             pmolTypeId: ppbPmolTypeId ?? null,
    //             siteManagerId: ppbSiteManagerId ?? null
    //         }));
    //     }
    // }, [projectSequenceCode]);

    const handleGenerateRFQ = () => {
        const filter = {
            executionDate: formatDateTimeToISOString(selectedDate),
            contractingUnit: [store.getState()?.projectDPlan?.selectedCu ? store.getState()?.projectDPlan?.selectedCu?.sequenceCode : userInfo?.contractingUnit?.sequnceCode],
            buId: store.getState()?.projectDPlan?.selectedBu,
        }
        dispatch(generateRFQ(filter))
        // console.log({filter});
    }

    const handleCIAW = () => {
        const ciaw = {
            date: formatDateTimeToISOString(selectedDate),
            buId: store.getState()?.projectDPlan?.selectedBu,
        }
        dispatch(generateCIAW(ciaw));
        // dispatch(saveProjectDayPlanningStateAttr('generateCIAW', true));
    };

    return (
      props.isTopPane ?
        <div className={`${classNames.aligment}`}>
            <div style={{display:'flex',gap:10,width:'100%',overflowX:'auto',overflowY:'hidden'}}>
                <div className={`${classNames.wrapper}`}>
                    <Dropdown
                      placeholder={t('viewMode')}
                      onChange={(ev, item) => {
                          dispatch(saveProjectDayPlanningStateAttr('projectDpViewMode', item?.key));
                      }}
                      selectedKey={projectDpViewMode ? projectDpViewMode : DpViewMode.OneHour}
                      disabled={false}
                      options={viewModeDp}
                    />
                </div>
                <PlanboardFilter/>
                <div className={`${classNames.btnWrapper}`}>
                    <PrimaryButton disabled={!(pmolList && pmolList?.length > 0)} text={t('generateRFQ')} onClick={()=>handleGenerateRFQ()}/>
                </div>

                <div className={`${classNames.btnWrapper}`}>
                    <PrimaryButton text={t('generateCIAW')} disabled={!(pmolList && pmolList?.length > 0)} onClick={() => handleCIAW()} />
                </div>

                <div className={`${classNames.btnWrapper}`}>
                    <PrimaryButton text={t('newProject')} disabled={false} onClick={
                        () => {
                            dispatch(saveProjectDayPlanningStateAttr('isNewProjectModalOpen', true))
                        }
                    } />
                </div>
                <div className={`${classNames.wrapper}`}>
                    <PrimaryButton
                      text={t('print')}
                      onClick={
                          () => {
                              dispatch(saveProjectDayPlanningStateAttr('isPrintModelOpen', true));
                          }
                      }
                      disabled={!isMyEnvironment()}
                    />
                </div>

                {props.onSelectDate &&
                    <div className={`${classNames.wrapper}`}>
                        <div style={{width:200,paddingLeft:5}}>
                            <DatePicker
                              onSelectDate={props?.onSelectDate}
                              placeholder={t('selectadate')}
                              ariaLabel={t('projectCompletionDate')}
                              value={stringDateToDateObject(selectedDate)}
                              strings={defaultCalendarStrings}
                            />
                        </div>
                    </div>}
                {props.refresh &&
                    <div className={`${classNames.wrapper}`}>
                        <div style={{width:200,paddingLeft:5}}>
                            <ActionButton iconProps={{iconName: 'Refresh'}} onClick={()=>{props.refresh()}} allowDisabledFocus >
                                {t('refresh')}
                            </ActionButton>
                        </div>
                    </div>}
            </div>

        </div>  :
        <div>
            <Pivot
              selectedKey={selectedResourceType}
              onLinkClick={(item?: PivotItem) => {
                  if (item) {
                      if (item.props.itemKey! === ProjectPlanResourceType.TEAM) {
                          dispatch(saveProjectDayPlanningStateAttr('selectedResourceType', ProjectPlanResourceType.TEAM));
                      } else if (item.props.itemKey! === ProjectPlanResourceType.TOOL) {
                          dispatch(saveProjectDayPlanningStateAttr('selectedResourceType', ProjectPlanResourceType.TOOL));
                      } else if (item.props.itemKey! === ProjectPlanResourceType.ORG_TEAMS) {
                          dispatch(saveProjectDayPlanningStateAttr('selectedResourceType', ProjectPlanResourceType.ORG_TEAMS));
                      }
                  }
              }}
            >
                <PivotItem headerText={t('worker/vehicle')} itemKey={`${ProjectPlanResourceType.TEAM}`} />
                <PivotItem headerText={t('tools')} itemKey={`${ProjectPlanResourceType.TOOL}`} />
                <PivotItem headerText={t('teams')} itemKey={`${ProjectPlanResourceType.ORG_TEAMS}`} />

            </Pivot>
            {selectedResourceType === ProjectPlanResourceType.TEAM && <PersonFilters type={"projectPlanBoard"}/>}
        </div>
    );
};

export default CommandBarLeft;
