import {
  CANCEL_CIAW,
  CANCEL_CIAW_FAIL,
  CANCEL_CIAW_SUCCESS,
  CIAW_CANCEL_DROPDOWN,
  CIAW_CANCEL_DROPDOWN_FAIL,
  CIAW_CANCEL_DROPDOWN_SUCCESS,
  CREATE_PROJECT_CIAW_SITE,
  CREATE_PROJECT_CIAW_SITE_FAIL,
  CREATE_PROJECT_CIAW_SITE_SUCCESS,
  CREATE_PRODUCT_CIAW_SITE,
  CREATE_PRODUCT_CIAW_SITE_FAIL,
  CREATE_PRODUCT_CIAW_SITE_SUCCESS,
  LOAD_CIAW_DATA,
  LOAD_CIAW_DATA_FAIL,
  LOAD_CIAW_DATA_SUCCESS,
  READ_CAB_CERTIFICATION_CIAW,
  READ_CAB_CERTIFICATION_CIAW_FAIL,
  READ_CAB_CERTIFICATION_CIAW_SUCCESS,
  READ_CIAW_BY_ID,
  READ_CIAW_BY_ID_FAIL,
  READ_CIAW_BY_ID_SUCCESS,
  READ_CIAW_DROPDOWN_DATA,
  READ_CIAW_DROPDOWN_DATA_FAIL,
  READ_CIAW_DROPDOWN_DATA_SUCCESS,
  READ_CIAW_SITE_CODE_BY_ID,
  READ_CIAW_SITE_CODE_BY_ID_FAIL,
  READ_CIAW_SITE_CODE_BY_ID_SUCCESS,
  READ_CIAW_SITE_PRODUCT_CODE_BY_ID,
  READ_CIAW_SITE_PRODUCT_CODE_BY_ID_FAIL,
  READ_CIAW_SITE_PRODUCT_CODE_BY_ID_SUCCESS,
  SAVE_CIAW_STATE_ATTR,
  SAVE_CIAW_STATE_PRODUCT_ATTR,
  SEND_CIAW,
  SEND_CIAW_FAIL,
  SEND_CIAW_SUCCESS,
  SEND_CIAW_WARNING_EMAIL,
  SEND_CIAW_WARNING_EMAIL_FAIL,
  SEND_CIAW_WARNING_EMAIL_SUCCESS,
} from '../actionTypes/ciawActionTypes';
import {
  CANCEL_CIAW_EP,
  CIAW_CANCEL_DROPDOWN_EP,
  CREATE_PROJECT_CIAW_SITE_EP,
  CREATE_PRODUCT_CIAW_SITE_EP,
  READ_CAB_CERTIFICATION_CIAW_EP,
  READ_CIAW_BY_ID_EP,
  READ_CIAW_DROPDOWN_DATA_EP,
  READ_CIAW_LIST_EP,
  READ_CIAW_SITE_CODE_BY_ID_EP,
  READ_CIAW_SITE_PRODUCT_CODE_BY_ID_EP,
  SEND_CIAW_EP,
  SEND_CIAW_WARNING_EMAIL_EP,
} from '../shared/endpoints';
import {format24hDateTime, getTomorrow} from "../shared/util";
import {CiawSiteCode,CiawSiteproductCode} from "../types/ciaw";
import moment from "moment/moment";

const defaultState: any = {
  ciawListData: [],
  isLoading: false,
  status: true,
  message: null,
  dropDownsData: [],
  ciawFilterData: {
    cabPerson: null,
    ciawStatus: null,
    date: moment(getTomorrow()).format('YYYY-MM-DD'),
    project: null,
    sorter: {
      attribute: null,
      order: null,
    }
  },
  selectedCiaw: null,
  ciawDropdownData: [],
  showProgressBar: false,
  formData: {},
  collapseBookmarkPane: false,
  isCiawSiteCodeModelOpen: false,
  isCiawSiteCodeProductModelOpen: false,
  ciawSiteCodeData: {},
  ciawSiteProductCodeData: {},
  timeClockSelectedDate: new Date(),
  currentActiveSectionTC: null,
  ciawCertificate: [],
  selectedCIAWListItems: [],
  ciawSendResult: null,
  ciawSiteCode: null,
  CiawSiteproductCode:null,
  ciawCancelResult: null,
  ciawFormData: null,
  ciawWarningEmailSendResult: null,
  ciawFilterDataexport:[]
}

export default function ciawReducer(state = defaultState, action: any) {
  if (action) {
    switch (action.type) {
      case LOAD_CIAW_DATA:
        return { ...state, isLoading: true };
      case LOAD_CIAW_DATA_SUCCESS:
        return {
          ...state,
          isLoading: false,
          status: action?.payload?.data?.status,
          message: action?.payload?.data?.message,
          ciawListData: formatCiawList(action?.payload?.data?.result),
        };
      case LOAD_CIAW_DATA_FAIL:
        return {
          ...state,
          isLoading: false,
        };

      case SAVE_CIAW_STATE_ATTR:
        const attr = Object.keys(action)[1];
        const value = Object.values(action)[1];
        return {
          ...state,
          [attr]: value
        };
      case SAVE_CIAW_STATE_PRODUCT_ATTR:
        const attr1 = Object.keys(action)[1];
        const value1 = Object.values(action)[1];
        return {
          ...state,
          [attr1]: value1
        };

      case READ_CIAW_DROPDOWN_DATA:
        return { ...state, ciawDropdownData: [] };
      case READ_CIAW_DROPDOWN_DATA_SUCCESS:
        return {
          ...state,
          ciawDropdownData: action?.payload?.data?.result,
        };
      case READ_CIAW_DROPDOWN_DATA_FAIL:
        return {
          ...state,
          ciawDropdownData: action?.payload?.data?.result,
        };

      case READ_CIAW_BY_ID:
        return { ...state, formData: {}, showProgressBar: true };
      case READ_CIAW_BY_ID_SUCCESS:
        return {
          ...state,
          formData: action?.payload?.data?.result,
          showProgressBar: false,
        };
      case READ_CIAW_BY_ID_FAIL:
        return {
          ...state,
          formData: action?.payload?.data?.result,
          showProgressBar: false,
        };
        case READ_CIAW_SITE_CODE_BY_ID:
          return { ...state, ciawSiteCodeData: {}, showProgressBar: true };
        case READ_CIAW_SITE_CODE_BY_ID_SUCCESS:
          return {
            ...state,
            ciawSiteCodeData: action?.payload?.data?.result,
            showProgressBar: false,
          };
        case READ_CIAW_SITE_CODE_BY_ID_FAIL:
          return {
            ...state,
            ciawSiteCodeData: {},
            showProgressBar: false,
          };
      case READ_CIAW_SITE_PRODUCT_CODE_BY_ID:
        return { ...state, ciawSiteProductCodeData: {}, showProgressBar: true };
      case READ_CIAW_SITE_PRODUCT_CODE_BY_ID_SUCCESS:
        return {
          ...state,
          ciawSiteProductCodeData: action?.payload?.data?.result,
          showProgressBar: false,
        };
      case READ_CIAW_SITE_PRODUCT_CODE_BY_ID_FAIL:
        return {
          ...state,
          ciawSiteProductCodeData: {},
          showProgressBar: false,
        };
        case CREATE_PROJECT_CIAW_SITE:
          return { ...state, ciawSiteCode: null, showProgressBar: true };
        case CREATE_PROJECT_CIAW_SITE_SUCCESS:
          return {
            ...state,
            ciawSiteCode: action?.payload?.data?.result,
            showProgressBar: false,
          };
        case CREATE_PROJECT_CIAW_SITE_FAIL:
          return {
            ...state,
            ciawSiteCode: null,
            showProgressBar: false,
          };
      case CREATE_PRODUCT_CIAW_SITE:
        return { ...state, ciawSiteproductCode: null, showProgressBar: true };
      case CREATE_PRODUCT_CIAW_SITE_SUCCESS:
        return {
          ...state,
          ciawSiteproductCode: action?.payload?.data?.result,
          showProgressBar: false,
        };
      case CREATE_PRODUCT_CIAW_SITE_FAIL:
        return {
          ...state,
          ciawSiteproductCode: null,
          showProgressBar: false,
        };

      case READ_CAB_CERTIFICATION_CIAW:
        return {
          ...state,
          ciawCertificate: [],
          showProgressBar: true
        };
      case READ_CAB_CERTIFICATION_CIAW_SUCCESS:
        return {
          ...state,
          ciawCertificate: action?.payload?.data?.result,
          showProgressBar: false,
        };
      case READ_CAB_CERTIFICATION_CIAW_FAIL:
        return {
          ...state,
          ciawCertificate: [],
          showProgressBar: false,
        };

      case SEND_CIAW:
        return {
          ...state,
          showProgressBar: true,
          ciawSendResult: null
        };
      case SEND_CIAW_SUCCESS:
        return {
          ...state,
          showProgressBar: false,
          ciawSendResult: action?.payload?.data?.result,
        };
      case SEND_CIAW_FAIL:
        return {
          ...state,
          showProgressBar: false,
          ciawSendResult: null
        };

      case CANCEL_CIAW:
        return {
          ...state,
          showProgressBar: true,
          ciawCancelResult: null
        };
      case CANCEL_CIAW_SUCCESS:
        return {
          ...state,
          showProgressBar: false,
          ciawCancelResult: action?.payload?.data?.result,
        };
      case CANCEL_CIAW_FAIL:
        return {
          ...state,
          showProgressBar: false,
          ciawCancelResult: null
        };
      case SEND_CIAW_WARNING_EMAIL:
        return {
          ...state,
          showProgressBar: true,
          ciawWarningEmailSendResult: null
        };
      case SEND_CIAW_WARNING_EMAIL_SUCCESS:
        return {
          ...state,
          showProgressBar: false,
          ciawWarningEmailSendResult: action?.payload?.data?.result,
        };
      case SEND_CIAW_WARNING_EMAIL_FAIL:
        return {
          ...state,
          showProgressBar: false,
          ciawWarningEmailSendResult: null
        }

      case CIAW_CANCEL_DROPDOWN:
        return {
          ...state,
        };
      case CIAW_CANCEL_DROPDOWN_SUCCESS:
        return {
          ...state,
          cancelLeaveType: action?.payload?.data?.result?.status,
        };
      case CIAW_CANCEL_DROPDOWN_FAIL:
        return {
          ...state,
        };

      default:
        return state;
    }
  }
}

export function saveCIAWStateAttr(attr: string, value: any) {
  return {
    type: SAVE_CIAW_STATE_ATTR,
    [attr]: value
  };
}

export function saveCIAWProductStateAttr(attr: string, value: any) {
  return {
    type: SAVE_CIAW_STATE_PRODUCT_ATTR,
    [attr]: value
  };
}

export const filterCiawListPaneData = (data: any) => {
  return {
    types: [
      LOAD_CIAW_DATA,
      LOAD_CIAW_DATA_SUCCESS,
      LOAD_CIAW_DATA_FAIL
    ],
    payload: {
      request: {
        url: READ_CIAW_LIST_EP,
        method: 'POST',
        data: data
      }
    }
  };
};

export const readCIAWDropdownData = () => {
  return {
    types: [
      READ_CIAW_DROPDOWN_DATA,
      READ_CIAW_DROPDOWN_DATA_SUCCESS,
      READ_CIAW_DROPDOWN_DATA_FAIL
    ],
    payload: {
      request: {
        url: READ_CIAW_DROPDOWN_DATA_EP,
      }
    }
  };
};

// export const readCIAWById = (id: string, productId?: string | null) => {
//   return {
//     types: [
//       READ_CIAW_BY_ID,
//       READ_CIAW_BY_ID_SUCCESS,
//       READ_CIAW_BY_ID_FAIL
//     ],
//     payload: {
//       request: {
//         method: 'POST',
//         url: READ_CIAW_BY_ID_EP,
//         data: {
//           ciawId: id,
//           productId: productId ? productId : null,
//         }
//       }
//     }
//   };
// };

export const readCIAWById = (id: string) => {
  return {
    types: [
      READ_CIAW_BY_ID,
      READ_CIAW_BY_ID_SUCCESS,
      READ_CIAW_BY_ID_FAIL
    ],
    payload: {
      request: {
        url: `${READ_CIAW_BY_ID_EP}/${id}`,
      }
    }
  };
};

export const readCIAWSiteCodeById = (id: any) => {
  return {
    types: [
      READ_CIAW_SITE_CODE_BY_ID,
      READ_CIAW_SITE_CODE_BY_ID_SUCCESS,
      READ_CIAW_SITE_CODE_BY_ID_FAIL
    ],
    payload: {
      request: {
        method: 'POST',
        url: READ_CIAW_SITE_CODE_BY_ID_EP + id,
      }
    }
  };
};

export const readCIAWSiteproductCodeById = (id: any) => {
  return {
    types: [
      READ_CIAW_SITE_PRODUCT_CODE_BY_ID,
      READ_CIAW_SITE_PRODUCT_CODE_BY_ID_SUCCESS,
      READ_CIAW_SITE_PRODUCT_CODE_BY_ID_FAIL
    ],
    payload: {
      request: {
        method: 'POST',
        url: READ_CIAW_SITE_PRODUCT_CODE_BY_ID_EP + id,
      }
    }
  };
};
export const validateCiawSiteCode = (data: CiawSiteCode) => {
  return {
    types: [
      CREATE_PROJECT_CIAW_SITE,
      CREATE_PROJECT_CIAW_SITE_SUCCESS,
      CREATE_PROJECT_CIAW_SITE_FAIL
    ],
    payload: {
      request: {
        method: 'POST',
        url: CREATE_PROJECT_CIAW_SITE_EP,
        data: data
      }
    }
  };
};
export const validateCiawSiteproductCode = (data: CiawSiteproductCode) => {
  return {
    types: [
      CREATE_PRODUCT_CIAW_SITE,
      CREATE_PRODUCT_CIAW_SITE_SUCCESS,
      CREATE_PRODUCT_CIAW_SITE_FAIL
    ],
    payload: {
      request: {
        method: 'POST',
        url: CREATE_PRODUCT_CIAW_SITE_EP,
        data: data
      }
    }
  };
};

export const readCabCertificationCIAW = (data: any) => {
  return {
    types: [
      READ_CAB_CERTIFICATION_CIAW,
      READ_CAB_CERTIFICATION_CIAW_SUCCESS,
      READ_CAB_CERTIFICATION_CIAW_FAIL
    ],
    payload: {
      request: {
        method: 'POST',
        url: READ_CAB_CERTIFICATION_CIAW_EP,
        data: data
      }
    }
  };
};

export const sendCIAW = (data: any) => {
  return {
    types: [
      SEND_CIAW,
      SEND_CIAW_SUCCESS,
      SEND_CIAW_FAIL
    ],
    payload: {
      request: {
        method: 'POST',
        url: SEND_CIAW_EP,
        data: data
      }
    }
  };
};

export const cancelCIAW = (data: any) => {
  return {
    types: [
      CANCEL_CIAW,
      CANCEL_CIAW_SUCCESS,
      CANCEL_CIAW_FAIL
    ],
    payload: {
      request: {
        method: 'POST',
        url: CANCEL_CIAW_EP,
        data: data
      }
    }
  };
};

export const ciawCancelDropDown = () => {
  return {
    types: [
      CIAW_CANCEL_DROPDOWN,
      CIAW_CANCEL_DROPDOWN_SUCCESS,
      CIAW_CANCEL_DROPDOWN_FAIL
    ],
    payload: {
      request: {
        url: CIAW_CANCEL_DROPDOWN_EP
      }
    }
  };
};

export const sendCIAWWarningEmail = (id: any) => {
  return {
    types: [
      SEND_CIAW_WARNING_EMAIL,
      SEND_CIAW_WARNING_EMAIL_SUCCESS,
      SEND_CIAW_WARNING_EMAIL_FAIL
    ],
    payload: {
      request: {
        method: 'GET',
        url: SEND_CIAW_WARNING_EMAIL_EP + id,
      }
    }
  };
}

const formatCiawList = (result: any) => {
    if (result) {
        return result.map((item: any) => {
          const getWeekByTitle = (title: string) => {
            const cert = item?.certificationStatuses.find((cert: any) => cert.title === title);
            return cert ? cert.weeks : 0;
        };
            return {
                ...item,
                title: item.projectTitle ? item.projectTitle : null,
                date: item.date ? format24hDateTime(item.date) : null,
                remark: item?.searchPresenceRegistrationItem ? item?.searchPresenceRegistrationItem?.lastValidation?.remarkList?.remark : [],
                SSNI: getWeekByTitle('SSNI'),
                A1: getWeekByTitle('A1'),
                LIMOSA: getWeekByTitle('Limosa'),
                VCA: getWeekByTitle('VCA')                
            };
        });
    }
    return [];
};