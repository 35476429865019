import React, {Component} from 'react';
import {connect} from 'react-redux';
import {IColumn, Selection} from '@fluentui/react/lib/DetailsList';
import history from '../../../history';
import {
  FilterItem,
  ListPaneItem,
  PBS_TYPE,
  PRODUCT_TAXONOMY_SHORT_CUT_PANE_ID,
  ProjectBreakdown,
  ShortCutPaneItem,
} from '../../../types/projectBreakdownStructure';
import ListPaneComponent from './component';
import {withRouter} from 'react-router';
import {WithTranslation, withTranslation} from 'react-i18next';
import {getById, readTaxonomyData} from '../../../reducers/projectBreakdownReducer';
import {messageService} from '../../../services/messageService';
import {getContractingUnit, getProject, isCBC} from '../../../shared/util';
import SortableTreeListView from '../../../shared/sortableTreeListView/sortableTreeListView';

export interface State {
  redirect: boolean;
  isChange: boolean;
  loading: boolean;
  filter: FilterItem;
  announcedMessage: string;
  selectedListItemIds: [] | string[];
  selectedListItemSequenceCode: [] | string[];
  formData: ProjectBreakdown;
  selectedListItems: ListPaneItem[];
}

export interface Props extends WithTranslation {
  listPaneItem: ListPaneItem[];
  reloadListPaneItem: () => void;
  handelFilter: (filter: FilterItem) => void;
  isDataLoaded?: boolean;
  isChange: boolean;
  filter: FilterItem;
  resourceTypes: [];
  loadMsg: string;
  toleranceStatesFilter: [],
  productStatesFilter: [],
  itemTypesFilter: [],
  handleSelectedListPaneItem: (selectedItemIds: [] | string[]) => void;
  formData: ProjectBreakdown;
  getById: (id: string) => void;
  projectId: string | null;
  reSizer: number;
  selectedShortCutItem?: ShortCutPaneItem;
  productTaxonomy: [];
  readTaxonomyData: () => void;
}

class ListPane extends Component<Props, State> {
  private _Selection: Selection;
  private _columns: IColumn[];
  private _columnsReSizer: IColumn[];

  subscription: any;

  constructor(props: Props) {
    super(props);
    this._Selection = new Selection({
      onSelectionChanged: () => {
        this.setState(
          {
            selectedListItemIds: this._getSelectedIds(),
            selectedListItemSequenceCode: this._getselectedSequenceCode(),
            selectedListItems: this._getSelectedListItems(),
          },
          // this.handleSelectedItem
          this.handleSelectedItem,
        );
      },
    });
    this.state = {
      selectedListItems: [],
      selectedListItemIds: [],
      selectedListItemSequenceCode: [],
      redirect: false,
      isChange: false,
      loading: false,
      announcedMessage: '',
      filter: {
        title: null,
        pbsToleranceStateId: null,
        qualityProducerCompany: null,
        qualityProducerName: null,
        pbsProductItemTypeId: null,
        pbsProductStatusId: null,
        scope: null,
        taxonomy: null,
        sorter: {
          attribute: null,
          order: null,
        },
      },
      formData: {
        id: null,
        title: null,
        headerTitle: null,
        contract: null,
        name: null,
        pbsProductItemTypeId: null,
        pbsProductStatusId: null,
        pbsToleranceStateId: null,
        productComposition: null,
        productDerivation: null,
        productFormatPresentation: null,
        productId: null,
        projectId: null,
        productPurpose: null,
        scope: null,
        historyLog: null,
        locationTaxonomyParentId: null,
        utilityTaxonomyParentId: null,
        pbsQualityResponsibilityId: null,
        qualityProducer: null,
        qualityProducerId: null,
        qualityReviewer: null,
        qualityReviewerId: null,
        qualityReviewerCompany: null,
        qualityApprover: null,
        qualityApproverId: null,
        isSaved: false,
        pbsType: PBS_TYPE.REGULAR,
        startDate: null,
        endDate: null,
        parentId: null,
        machineTaxonomyParentId: null,
      },
    };

    this._columns = [

      {
        key: 'column1',
        name: this.props.t('title'),
        fieldName: 'title',
        minWidth: 100,
        maxWidth: 200,
        isResizable: true,
        isSorted: true,
        isSortedDescending: true,
        onColumnClick: this._onColumnClick,
      },
      {
        key: 'column2',
        name: this.props.t('type'),
        fieldName: 'pbsProductItemType',
        minWidth: 100,
        maxWidth: 200,
        isResizable: true,
        onColumnClick: this._onColumnClick,
      },
      {
        key: 'column3',
        name: this.props.t('company'),
        fieldName: 'qualityProducerCompany',
        minWidth: 100,
        maxWidth: 200,
        isResizable: true,
        onColumnClick: this._onColumnClick,
      },
      {
        key: 'column4',
        name: this.props.t('qualityProducer'),
        fieldName: 'qualityProducerName',
        minWidth: 100,
        maxWidth: 200,
        isResizable: true,
        onColumnClick: this._onColumnClick,
      },
      {
        key: 'column5',
        name: this.props.t('productstate'),
        fieldName: 'pbsProductStatus',
        minWidth: 100,
        maxWidth: 200,
        isResizable: true,
        onColumnClick: this._onColumnClick,
      },
      {
        key: 'column6',
        name: this.props.t('productToleranceStatus'),
        fieldName: 'pbsToleranceState',
        minWidth: 100,
        maxWidth: 200,
        isResizable: true,
        onColumnClick: this._onColumnClick,
      },
    ];

    this._columnsReSizer = [
      {
        key: 'column1',
        name: this.props.t('title'),
        fieldName: 'title',
        minWidth: 165,
        maxWidth: 200,
        isResizable: true,
        onColumnClick: this._onColumnClick,
      },
    ];

  }

  static getDerivedStateFromProps(nextProps: any) {
    if (nextProps.isChange) {
      nextProps.reloadListPaneItem();
    }
    return {
      loading: false,
      filter: nextProps.filter,
      formData: nextProps.formData,
    };
  }

  componentWillUnmount() {
    this.subscription.unsubscribe();
  }

  componentDidMount() {
    this.subscription = messageService.getMessage().subscribe((data: any) => {
      if (data) {
        if (data.data.isNewPbs) {
          this._Selection.selectToKey('', true);
        }
        if (data.data.resetListPaneSelection) {
          this._Selection.setAllSelected(false);
          this.resetSelection();
        }
      }
    });
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    if (prevProps.isDataLoaded != this.props.isDataLoaded) {
      if (this.props.isDataLoaded) {
        this.resetSelection();
      }
    }
    if (prevProps.selectedShortCutItem != this.props.selectedShortCutItem) {
      if (this.props.selectedShortCutItem?.id === PRODUCT_TAXONOMY_SHORT_CUT_PANE_ID) {
        this.props.readTaxonomyData();
      }
    }
  };

  handleListPaneItemClick = (id: string) => {
    //this.props.getById(id);
    if (isCBC()) {
      if (getContractingUnit()) {
        history.push(`/CU/${getContractingUnit()}/organisation-cbc/${id}`);
      } else {
        history.push(`/organisation-cbc/${id}`);
      }

    } else if (getContractingUnit()) {
      if (getContractingUnit()) {
        history.push(`/CU/${getContractingUnit()}/project/${getProject()}/product/pbs/${id}`);
      } else {
        history.push(`/project/${this.props.projectId}/product/pbs/${id}`);
      }
    }

  };

  handleSelectedItem = () => {
    this.props.handleSelectedListPaneItem(this.state.selectedListItemIds);
    if (this.state.selectedListItemSequenceCode) {
      let lastId = this.state.selectedListItemSequenceCode.pop();
      if (lastId) {
        if (isCBC()) {
          history.push(`/organisation-cbc/${lastId}`);
        } else {
          if (getContractingUnit()) {
            history.push(`/CU/${getContractingUnit()}/project/${getProject()}/product/pbs/${lastId}`);
          } else {
            history.push(`/project/${this.props.projectId}/product/pbs/${lastId}`);
          }
        }
      }
    }
  };

  handleFilterChange = (filter: FilterItem) => {
    this.props.handelFilter(filter);
  };

  handleFilterSortChange = () => {
    this.props.handelFilter(this.state.filter);
  };

  resetSelection = () => {
    if (this.state.formData && this.state.formData.productId && this.props.isDataLoaded) {
      this._Selection.setKeySelected(
        this.state.formData.productId,
        true,
        false,
      );
    } else {
      this._Selection.selectToKey('', true);
    }
  };

  handleTitleClick = (node: any) => {
    messageService.sendMessage({ selectedOrgSetting: node });
    this.handleListPaneItemClick(node?.id);
  };

  render() {
    return (
        <div>
          {/*{JSON.stringify(this.props.selectedShortCutItem)}*/}
          {this.props.selectedShortCutItem?.id === PRODUCT_TAXONOMY_SHORT_CUT_PANE_ID
              ? <div className="org" style={{marginTop: 10}}>
                <SortableTreeListView
                    treeData={this.props.productTaxonomy}
                    handleTitleClick={(node: any) => {
                      this.handleTitleClick(node);
                    }}/>
              </div>
              : <ListPaneComponent
                  listPaneItem={this.props.listPaneItem}
                  selection={this._Selection}
                  handleListPaneItemClick={(id: string) => this.handleListPaneItemClick(id)}
                  resetSelection={() => this.resetSelection()}
                  formData={this.state.formData}
                  columns={(this.props.reSizer === 300) ? this._columnsReSizer : this._columns}
                  filter={this.props.filter}
                  loadMsg={this.props.loadMsg}
                  resourceTypes={this.props.resourceTypes}
                  handleFilterChange={(filter: FilterItem) =>
                      this.handleFilterChange(filter)
                  }
                  isDataLoaded={this.props.isDataLoaded}
                  dropDownOptions={
                    {
                      toleranceStatesFilter: this.props.toleranceStatesFilter,
                      productStatesFilter: this.props.productStatesFilter,
                      itemTypesFilter: this.props.itemTypesFilter,
                    }
                  }
              />}
        </div>
    );
  }

  private _getSelectedIds() {
    let deleteList: any = [];
    let SelectedItem = this._Selection.getSelection() as ListPaneItem[];
    if (SelectedItem) {
      deleteList = SelectedItem.map((item: any) => {
        return (item) ? item.id : null;
      });
    }
    return deleteList;
  }

  private _getselectedSequenceCode() {
    let deleteList: any = [];
    let SelectedItem = this._Selection.getSelection() as ListPaneItem[];
    if (SelectedItem) {
      deleteList = SelectedItem.map((item: any) => {
        return (item) ? item.productId : null;
      });
    }
    return deleteList;
  }

  private _getSelectedListItems = () => {
    let SelectedListItems = this._Selection.getSelection() as ListPaneItem[];

    return SelectedListItems;
  };

  private _onColumnClick = (
    ev: React.MouseEvent<HTMLElement>,
    column: IColumn,
  ): void => {
    const newColumns: IColumn[] = this._columns.slice();
    const currColumn: IColumn = newColumns.filter(
      (currCol) => column.key === currCol.key,
    )[0];
    newColumns.forEach((newCol: IColumn) => {
      if (newCol === currColumn) {
        currColumn.isSortedDescending = !currColumn.isSortedDescending;
        currColumn.isSorted = true;
        let filter = this.state.filter;

        let fieldName = currColumn.fieldName;

        let sortby = currColumn.isSortedDescending ? 'desc' : 'asc';
        filter.sorter.attribute = fieldName ? fieldName : null;
        filter.sorter.order = sortby ? sortby : null;
        this.setState(
          {
            filter: filter,
          },
          this.handleFilterSortChange,
        );
      } else {
        newCol.isSorted = false;
        newCol.isSortedDescending = true;
      }
    });
  };
}

const mapStateToProps = (state: any, ownProps: any) => {
  return {
    toleranceStatesFilter: state.projectBreakdown.toleranceStatesFilter,
    productStatesFilter: state.projectBreakdown.productStatesFilter,
    itemTypesFilter: state.projectBreakdown.itemTypesFilter,
    isChange: state.projectBreakdown.isChange,
    productTaxonomy: state.projectBreakdown.productTaxonomy,
  };
};

const mapDispatchToProps = {
  getById,
  readTaxonomyData,
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(withTranslation()(ListPane)),
);
