import React, {SyntheticEvent, useEffect, useRef, useState} from 'react';
import {GanttProps, Task, ViewMode} from '../../types/public-types';
import {GridProps} from '../grid/grid';
import {ganttDateRange, seedDates} from '../../helpers/date-helper';
import {CalendarProps} from '../calendar/calendar';
import {TaskGanttContentProps} from './task-gantt-content';
import {TaskListHeaderDefault} from '../task-list/task-list-header';
import {TaskListTableDefault} from '../task-list/task-list-table';
import {StandardTooltipContent, Tooltip} from '../other/tooltip';
import {VerticalScroll} from '../other/vertical-scroll';
import {TaskList, TaskListProps} from '../task-list/task-list';
import {TaskGantt} from './task-gantt';
import {BarTask} from '../../types/bar-task';
import {convertToBarTasks} from '../../helpers/bar-helper';
import {GanttEvent} from '../../types/gantt-task-actions';
import {DateSetup} from '../../types/date-setup';
import styles from './gantt.module.css';
import {HorizontalScroll} from '../other/horizontal-scroll';
import {removeHiddenTasks} from '../../helpers/other-helper';
import moment from 'moment';
import {useSelector} from 'react-redux';
import ResizablePanels from '../../../resizeblePanel/index';

export const Gantt: React.FunctionComponent<GanttProps> = ({
    tasks,
    listTasks,
    isInitialCollaps,
    headerHeight = 50,
    columnWidth = 60,
    listCellWidth = '155px',
    rowHeight = 36,
    ganttHeight = 0,
    viewMode = ViewMode.Day,
    locale = 'en-GB',
    barFill = 60,
    barCornerRadius = 3,
    barProgressColor = '#a3a3ff',
    barProgressSelectedColor = '#8282f5',
    barBackgroundColor = '#b8c2cc',
    barBackgroundSelectedColor = '#aeb8c2',
    projectProgressColor = '#7db59a',
    projectProgressSelectedColor = '#59a985',
    projectBackgroundColor = '#fac465',
    projectBackgroundSelectedColor = '#f7bb53',
    milestoneBackgroundColor = '#f1c453',
    milestoneBackgroundSelectedColor = '#f29e4c',
    rtl = false,
    handleWidth = 8,
    timeStep = 300000,
    arrowColor = 'grey',
    fontFamily = 'Arial, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue',
    fontSize = '14px',
    arrowIndent = 20,
    todayColor = 'rgba(252, 248, 227, 0.5)',
    TooltipContent = StandardTooltipContent,
    TaskListHeader = TaskListHeaderDefault,
    TaskListTable = TaskListTableDefault,
    onDateChange,
    onProgressChange,
    onDoubleClick,
    onRightClick,
    onDelete,
    onSelect,
    onExpanderClick,
    onVisibilityChanged,
    onVisibilityToggle,
    canDrag,
    canDrop,
    chartType,
    onTreeDataChange,
    searchQuery,
    searchMethod,
    searchFocusOffset,
    searchFinishCallback,
    onlyExpandSearchedNodes,
    onMoveNode,
    showGanttChart,
    showAdditionalFilters,
    selectedScreen
}) => {
    const wrapperRef = useRef<HTMLDivElement>(null);
    const taskListRef = useRef<HTMLDivElement>(null);
    const [ dateSetup, setDateSetup ] = useState<DateSetup>(() => {
        const [ startDate, endDate ] = ganttDateRange(tasks, viewMode);
        return { viewMode, dates: seedDates(startDate, endDate, viewMode) };
    });

    const [ taskHeight, setTaskHeight ] = useState((rowHeight * barFill) / 100);
    const [ taskListWidth, setTaskListWidth ] = useState(0);
    const [ svgContainerWidth, setSvgContainerWidth ] = useState(0);
    const [ svgContainerHeight, setSvgContainerHeight ] = useState(ganttHeight);
    const [ barTasks, setBarTasks ] = useState<BarTask[]>([]);
    const [ ganttEvent, setGanttEvent ] = useState<GanttEvent>({ action: '' });

    const [ selectedTask, setSelectedTask ] = useState<BarTask>();
    const [ failedTask, setFailedTask ] = useState<BarTask | null>(null);

    const svgWidth = dateSetup.dates.length * columnWidth;
    const ganttFullHeight = barTasks.length * rowHeight;

    const [ scrollY, setScrollY ] = useState(0);
    const [ preViewMode, setViewMode ] = useState(ViewMode.Day);
    const [ scrollX, setScrollX ] = useState(-1);
    const [isInitialScrollx, setIsInitialScrollex] = useState(true);
    const [ ignoreScrollEvent, setIgnoreScrollEvent ] = useState(false);

    const scrollPosDate: any = useSelector(
        (state: any) => state.vp.scrollPosDate
      );

    const showvpProjectDocumentPane: any = useSelector(
        (state: any) => state.vp.showvpProjectDocumentPane
      );

    // task change events
    useEffect(() => {
        let filteredTasks: Task[];
        if (onExpanderClick) {
            filteredTasks = removeHiddenTasks(tasks);
        } else {
            filteredTasks = tasks;
        }
        const [ startDate, endDate ] = ganttDateRange(filteredTasks, viewMode);
        let newDates = seedDates(startDate, endDate, viewMode);
        const latestDate = newDates[0];
        if (rtl) {
            newDates = newDates.reverse();
            if (scrollX === -1) {
                setScrollX(newDates.length * columnWidth);
            }
        }
        const currntDate = new Date();

        if (preViewMode !== viewMode) {
            let count = 0
            switch(viewMode) {
                case ViewMode.QuarterDay:
                    count = moment.duration(moment(currntDate).diff(moment(latestDate))).asDays()*4;
                    break
                case ViewMode.HalfDay:
                    count = moment.duration(moment(currntDate).diff(moment(latestDate))).asDays()*2;
                    break
                case ViewMode.Day:
                    count = moment.duration(moment(currntDate).diff(moment(latestDate))).asDays();
                    break
                case ViewMode.Week:
                    count = moment.duration(moment(currntDate).diff(moment(latestDate))).asWeeks();
                    break
                case ViewMode.Month:
                    count = moment.duration(moment(currntDate).diff(moment(latestDate))).asMonths();
                    break
                case ViewMode.QuaterYear:
                    count = moment.duration(moment(currntDate).diff(moment(latestDate))).asMonths()/4;
                    break
                default: 
                    count = moment.duration(moment(currntDate).diff(moment(latestDate))).asDays();
            }
            
            setScrollX((count * columnWidth)-300);
        }
       
       
        setViewMode(viewMode)
        setDateSetup({ dates: newDates, viewMode });
        setBarTasks(
            convertToBarTasks(
                filteredTasks,
                newDates,
                columnWidth,
                rowHeight,
                taskHeight,
                barCornerRadius,
                handleWidth,
                rtl,
                barProgressColor,
                barProgressSelectedColor,
                barBackgroundColor,
                barBackgroundSelectedColor,
                projectProgressColor,
                projectProgressSelectedColor,
                projectBackgroundColor,
                projectBackgroundSelectedColor,
                milestoneBackgroundColor,
                milestoneBackgroundSelectedColor
            )
        );
    }, [
        tasks,
        viewMode,
        rowHeight,
        barCornerRadius,
        columnWidth,
        taskHeight,
        handleWidth,
        barProgressColor,
        barProgressSelectedColor,
        barBackgroundColor,
        barBackgroundSelectedColor,
        projectProgressColor,
        projectProgressSelectedColor,
        projectBackgroundColor,
        projectBackgroundSelectedColor,
        milestoneBackgroundColor,
        milestoneBackgroundSelectedColor,
        rtl,
        scrollX,
        onExpanderClick,
        onVisibilityChanged
    ]);

    useEffect(() => {
        const {changedTask, action} = ganttEvent;
        if (changedTask) {
            if (action === 'delete') {
                setGanttEvent({action: ''});
                setBarTasks(barTasks.filter(t => t.id !== changedTask.id));
            } else if (
              action === 'move' ||
              action === 'end' ||
              action === 'start' ||
              action === 'progress'
            ) {
                const prevStateTask = barTasks.find(t => t.id === changedTask.id);
                if (
                  prevStateTask &&
                  (prevStateTask.start.getTime() !== changedTask.start.getTime() ||
                    prevStateTask.end.getTime() !== changedTask.end.getTime() ||
                    prevStateTask.progress !== changedTask.progress)
                ) {
                    // actions for change
                    const newTaskList = barTasks.map(t =>
                      t.id === changedTask.id ? changedTask : t
                    );
                    setBarTasks(newTaskList);
                }
            }
        }
    }, [ganttEvent, barTasks]);

    useEffect(() => {
        if (failedTask) {
            setBarTasks(barTasks.map(t => (t.id !== failedTask.id ? t : failedTask)));
            setFailedTask(null);
        }
    }, [ failedTask, barTasks ]);

    useEffect(() => {
        const newTaskHeight = (rowHeight * barFill) / 100;
        if (newTaskHeight !== taskHeight) {
            setTaskHeight(newTaskHeight);
        }
    }, [ rowHeight, barFill, taskHeight ]);

    useEffect(() => {
        if (!listCellWidth) {
            setTaskListWidth(0);
        }
        if (taskListRef.current) {
            setTaskListWidth(taskListRef.current.offsetWidth);
        }
    }, [ taskListRef, listCellWidth ]);

    useEffect(() => {
        if (wrapperRef.current) {
            setSvgContainerWidth(wrapperRef.current.offsetWidth - taskListWidth);
        }
    }, [ wrapperRef, taskListWidth ]);

    useEffect(() => {
        if (ganttHeight) {
            setSvgContainerHeight(ganttHeight + headerHeight);

        } else {
            setSvgContainerHeight((tasks.length * rowHeight) + headerHeight);
            //setSvgContainerHeight(560);
        }
    }, [ ganttHeight, tasks ]);

    // scroll events
    useEffect(() => {
        const handleWheel = (event: WheelEvent) => {
            if (event.shiftKey || event.deltaX) {
                const scrollMove = event.deltaX ? event.deltaX : event.deltaY;
                let newScrollX = scrollX + scrollMove;
                if (newScrollX < 0) {
                    newScrollX = 0;
                } else if (newScrollX > svgWidth) {
                    newScrollX = svgWidth;
                }
                setScrollX(newScrollX);
                event.preventDefault();
            } else if (ganttHeight) {
                let newScrollY = scrollY + event.deltaY;
                if (newScrollY < 0) {
                    newScrollY = 0;
                } else if (newScrollY > ganttFullHeight - ganttHeight) {
                    newScrollY = ganttFullHeight - ganttHeight;
                }
                if (newScrollY !== scrollY) {
                    setScrollY(newScrollY);
                    event.preventDefault();
                }
            }

            setIgnoreScrollEvent(true);
        };

        // subscribe if scroll is necessary
        if (wrapperRef.current) {
            wrapperRef.current.addEventListener('wheel', handleWheel, { passive: false });
        }
        return () => {
            if (wrapperRef.current) {
                wrapperRef.current.removeEventListener('wheel', handleWheel);
            }
        };
    }, [ wrapperRef.current, scrollY, scrollX, ganttHeight, svgWidth, rtl ]);

    const handleScrollY = (event: SyntheticEvent<HTMLDivElement>) => {
        if (scrollY !== event.currentTarget.scrollTop && !ignoreScrollEvent) {
            setScrollY(event.currentTarget.scrollTop);
        }
        setIgnoreScrollEvent(false);
    };

    const handleScrollX = (event: SyntheticEvent<HTMLDivElement>) => {
        if (scrollX !== event.currentTarget.scrollLeft && !ignoreScrollEvent) {
            //RISK: Commented this to fix scroll issue when change view mode. don't know the side effect
            // setScrollX(event.currentTarget.scrollLeft);
        }
        setIsInitialScrollex(false)
        setIgnoreScrollEvent(false);
    };

    /**
   * Handles arrow keys events and transform it to new scroll
   */
    const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
        event.preventDefault();
        let newScrollY = scrollY;
        let newScrollX = scrollX;
        let isX = true;
        switch (event.key) {
        case 'Down': // IE/Edge specific value
        case 'ArrowDown':
            newScrollY += rowHeight;
            isX = false;
            break;
        case 'Up': // IE/Edge specific value
        case 'ArrowUp':
            newScrollY -= rowHeight;
            isX = false;
            break;
        case 'Left':
        case 'ArrowLeft':
            newScrollX -= columnWidth;
            break;
        case 'Right': // IE/Edge specific value
        case 'ArrowRight':
            newScrollX += columnWidth;
            break;
        }
        if (isX) {
            if (newScrollX < 0) {
                newScrollX = 0;
            } else if (newScrollX > svgWidth) {
                newScrollX = svgWidth;
            }
            setScrollX(newScrollX);
        } else {
            if (newScrollY < 0) {
                newScrollY = 0;
            } else if (newScrollY > ganttFullHeight - ganttHeight) {
                newScrollY = ganttFullHeight - ganttHeight;
            }
            setScrollY(newScrollY);
        }
        setIgnoreScrollEvent(true);
    };

    /**
   * Task select event
   */
    const handleSelectedTask = (taskId: string) => {
        const newSelectedTask = barTasks.find(t => t.id === taskId);
        const oldSelectedTask = barTasks.find(
            t => !!selectedTask && t.id === selectedTask.id
        );
        if (onSelect) {
            if (oldSelectedTask) {
                onSelect(oldSelectedTask, false);
            }
            if (newSelectedTask) {
                onSelect(newSelectedTask, true);
            }
        }
        setSelectedTask(newSelectedTask);
    };
    const handleExpanderClick = (task: Task) => {
        if (onExpanderClick && task.hideChildren !== undefined) {
            onExpanderClick({ ...task, hideChildren: !task.hideChildren });
        }
    };

    const handleVisibilityChange = (task: any) => {
        if (onVisibilityChanged) {
            onVisibilityChanged(task);
        }
    };

    const handleVisibilityToggle = (data: any) => {
        if (onVisibilityToggle) {
            onVisibilityToggle(data);
        }
    };

    const gridProps: GridProps = {
        columnWidth,
        svgWidth,
        tasks: tasks,
        rowHeight,
        dates: dateSetup.dates,
        todayColor,
        rtl,
        listTasks,
        isInitialCollaps,
        canDrag,
        canDrop,
        chartType,
        onTreeDataChange,
        onMoveNode,
        viewMode,
    };
    const calendarProps: CalendarProps = {
        dateSetup,
        locale,
        viewMode,
        headerHeight,
        columnWidth,
        fontFamily,
        fontSize,
        rtl
    };
    const barProps: TaskGanttContentProps = {
        tasks: barTasks,
        dates: dateSetup.dates,
        ganttEvent,
        selectedTask,
        rowHeight,
        taskHeight,
        columnWidth,
        arrowColor,
        timeStep,
        fontFamily,
        fontSize,
        arrowIndent,
        svgWidth,
        rtl,
        setGanttEvent,
        setFailedTask,
        setSelectedTask: handleSelectedTask,
        onDateChange,
        onProgressChange,
        onDoubleClick,
        onRightClick,
        onDelete,
        chartType,
    };

    const tableProps: TaskListProps = {
        rowHeight,
        rowWidth: listCellWidth,
        fontFamily,
        fontSize,
        tasks: barTasks,
        locale,
        headerHeight,
        scrollY,
        ganttHeight,
        horizontalContainerClass: styles.horizontalContainer,
        selectedTask,
        taskListRef,
        setSelectedTask: handleSelectedTask,
        onExpanderClick: handleExpanderClick,
        onVisibilityChanged: handleVisibilityChange,
        onVisibilityToggle: handleVisibilityToggle,
        TaskListHeader,
        TaskListTable,
        listTasks,
        isInitialCollaps,
        canDrag,
        canDrop,
        onMoveNode,
        chartType,
        searchQuery,
        searchMethod,
        searchFocusOffset,
        searchFinishCallback,
        onlyExpandSearchedNodes,
        onTreeDataChange: onTreeDataChange,
        selectedScreen
    };

    return (
      <div>
          <div
            className={styles.wrapper + '111111C'}
            onKeyDown={handleKeyDown}
            tabIndex={0}
            ref={wrapperRef}
          >
              <ResizablePanels
                bkcolor="#ffffff"
                displayDirection="row"
                width="100%"
                height={`${screen.height-436}px`}
                panelsSize={showvpProjectDocumentPane ? [50, 50] : [44, 65]}
                sizeUnitMeasure="vw"
                resizerColor="#ffffff"
                resizerSize="2px"
                showGanttChart={showGanttChart}
                showAdditionalFilters={showAdditionalFilters}
              >
                  {listCellWidth && <TaskList {...tableProps} />}
                  <TaskGantt
                    gridProps={gridProps}
                    calendarProps={calendarProps}
                    barProps={barProps}
                    ganttHeight={ganttHeight}
                    scrollY={scrollY}
                    scrollX={scrollX}
                    chartType={chartType}
                    viewMode={viewMode}

                />
             </ResizablePanels>
                { ganttEvent.changedTask && (
                    <Tooltip
                        arrowIndent={ arrowIndent }
                        rowHeight={ rowHeight }
                        svgContainerHeight={ svgContainerHeight }
                        svgContainerWidth={ svgContainerWidth }
                        fontFamily={ fontFamily }
                        fontSize={ fontSize }
                        scrollX={ scrollX }
                        scrollY={ scrollY }
                        task={ ganttEvent.changedTask }
                        headerHeight={ headerHeight }
                        taskListWidth={ taskListWidth }
                        TooltipContent={ TooltipContent }
                        rtl={ rtl }
                        svgWidth={ svgWidth }
                    />
                ) }
                <VerticalScroll
                    ganttFullHeight={ ganttFullHeight }
                    ganttHeight={ ganttHeight }
                    headerHeight={ headerHeight }
                    scroll={ scrollY }
                    onScroll={ handleScrollY }
                    rtl={ rtl }
                />
            </div>
            <HorizontalScroll
                svgWidth={ svgWidth }
                taskListWidth={ taskListWidth }
                scroll={ scrollX }
                rtl={ rtl }
                onScroll={ handleScrollX }
            />
        </div>
    );
};
