import React, {useEffect, useRef, useState} from 'react';
import {
    ChoiceGroup,
    CommandBarButton,
    DirectionalHint,
    FontIcon,
    IButtonStyles,
    IPersonaProps,
    IStackItemStyles,
    IStackStyles,
    IStackTokens,
    Label,
    mergeStyles,
    Persona,
    PersonaPresence,
    PersonaSize,
    registerIcons,
    Separator,
    Stack,
    TooltipHost,
} from '@fluentui/react';
import {getPmolType, getStatesIcon, getWeatherIcon} from '../../../types/myDayPlanning';
import {SvgIcons} from '../../../shared/svgIcons/svgIcons';
import {useId} from '@uifabric/react-hooks';
import {saveMyDayPlanningStateAttr} from '../../../reducers/myDayPlanningReducer';
import {saveStateAttr} from '../../../reducers/uprinceReducer';
import {useDispatch, useSelector} from 'react-redux';
import {saveProjectDayPlanningStateAttr} from '../../../reducers/projectDayPlanningReducer';
import '../../myDayPlanning/listPane/eventBody.css';
import {useTranslation} from 'react-i18next';
import {Popover} from '@varld/popover';
import {useSingleAndDoubleClick} from '../../../hooks/useSingleAndDoubleClick';
import {PROJECT_PLANNING_ROUTE} from '../../../types/types';

registerIcons(SvgIcons);
// Non-mutating styles definition
const stackItemStylesStatus: IStackItemStyles = {
    root: {
        alignItems: 'center',
        display: 'flex',
        height: 30,
        justifyContent: 'end',
        paddingRight: 0,
        paddingBottom: 0,
        margin: '5px 0px 0px 5px',
        '&:first-child': {
            flex: 0,
        },
    },
};
const stackItemStylesDot: IStackItemStyles = {
    root: {
        alignItems: 'center',
        display: 'flex',
        height: 40,
        justifyContent: 'end',
        paddingRight: 10,
        paddingBottom: 16
    },
};
const nonShrinkingStackItemStyles: IStackItemStyles = {
    root: {
        alignItems: 'center',
        display: 'flex',
        height: 40,
        justifyContent: 'start',
        overflow: 'hidden',
        width: 500,
    },
};

// Tokens definition
const outerStackTokens: IStackTokens = {childrenGap: 1};
const innerStackTokens: IStackTokens = {
    childrenGap: 1,
};
const styles = {
    logo: {
        marginLeft: 0,
        marginRight: 0,
        width: 30,
        height: 30,
    },
    notDone: {
        marginLeft: 0,
        marginRight: 0,
        width: 28,
        height: 28,
        border: '2px solid red'
    },
    done: {
        marginLeft: 0,
        marginRight: 0,
        width: 28,
        height: 28,
        border: '2px solid green'
    },
    logoHover: {
        color: 'black',
        marginLeft: 0,
        marginRight: 0,
        width: 30,
        height: 30,
        fontSize: 12,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    dot: {
        width: 10,
        height: 10
    },
    whetherLogo: {
        marginLeft: 'auto',
        marginRight: 'auto',
        width: 20,
        height: 20,
        fontSize: 14,
        fontWeight: 300,
        marginTop: 9,
        color: '#000000'
    },
    peopleLogo: {
        marginLeft: 'auto',
        marginRight: 'auto',
        width: 20,
        height: 20,
        fontSize: 14,
        fontWeight: 300,
        marginTop: 9,
        color: '#000000',
    },
    resourceLogo: {
        marginLeft: 4,
        marginRight: 'auto',
        width: 20,
        height: 20,
        fontSize: 14,
        fontWeight: 300,
        color: '#000000'
    },
    whetherCol: {
        display: 'flex',
        alignItems: 'center',
        minWidth: 17,
        color: '#000000',
    },
};

const buttonStyles: Partial<IButtonStyles> = {
    root: {
        minWidth: 0,
        padding: '0 4px',
        alignSelf: 'stretch',
        height: 'auto',
    },
};

const iconClass = mergeStyles({
    fontSize: 14,
    height: 14,
    width: 14,
    margin: '0 25px',
    color: 'black',
});

const EventBody = (props: {
    pmol: any,
    overflowItems: any,
    isDp?: boolean,
    handleCompetencyModal?: any,
    handlePmolStatusChange?: any,
    isMyCal?: boolean,
}) => {
    const {t} = useTranslation();
    const {pmol, overflowItems, isDp, handlePmolStatusChange, isMyCal} = props;
    const pmolVehical = isDp ? pmol.pmolVehical : pmol.pomlVehical
    const pmolTool = isDp ? pmol.pmolTool : pmol.pomlTool
    const eventRef: any = useRef(null);
    const tooltipRef: any = useRef(null);
    // Mutating styles definition
    const dispatch = useDispatch();

    const [iconName, setIconName] = useState(`${getStatesIcon(pmol?.statusId) + '-' + getPmolType(pmol)}`);
    const [isHover, setIsHover] = useState(false);
    const [pmolStatusId, setPmolStatusId] = useState(pmol?.statusId);

    const stackStyles: IStackStyles = {
        root: {
            overflow: 'hidden',
            width: `100%`,
        },
    };
    const stackStylesResource: IStackStyles = {
        root: {
            overflow: 'auto',
            width: `100%`,
            paddingLeft: 10,
            paddingTop: 10,
        },
    };
    const stackStylesTimeBar: IStackStyles = {
        root: {
            overflow: 'hidden',
            width: `100%`,
            borderBottom: '1px solid rgba(0, 0, 0, 0.25)',
            borderTop: '1px solid rgba(0, 0, 0, 0.25)',
            height: 40,
            alignItems: 'center'
        },
    };

    const {pmolStatus} = useSelector(
      (state: any) => state.pmol
    );

    useEffect(() => {
        const handleClick = (e: any) => {
            const tooltipSpan = tooltipRef.current;
            const x = e.clientX, y = e.clientY;
            if (tooltipSpan) {
                tooltipSpan.style.top = (y + 20) + 'px';
                tooltipSpan.style.left = (x + 20) + 'px';
            }
        };

        const element = eventRef.current;

        element.addEventListener('mousemove', handleClick);

        return () => {
            element.removeEventListener('mousemove', handleClick);
        };
    }, [])


    const renderTeamMembers = (teams: any) => {

        if (teams && teams.length > 0) {
            return <div>
                {
                    teams.map((emp: any) => {
                        const examplePersona: IPersonaProps = {
                            imageUrl: '',
                            text: emp.name,
                            secondaryText: emp.role,
                            styles: {
                                root: {
                                    color: '#10100f',
                                },
                                primaryText: {
                                    color: '#10100f',
                                    fontSize: 10,
                                    '&:hover': {
                                        color: '#10100f',
                                    },
                                },
                                secondaryText: {
                                    color: '#10100f',
                                    fontSize: 9,
                                },
                                textContent: {
                                    paddingBottom: 10
                                },
                            },
                        };
                        return <span style={{paddingBottom: 1}} key={emp.id}>
              <div style={{height: 3}}></div>
              <div className="find me" style={{display: 'flex', alignItems: 'center'}}>
                <Persona
                  {...examplePersona}
                  size={PersonaSize.size56}
                  presence={PersonaPresence.none}
                  imageAlt={emp.name}
                  coinSize={20}
                />
                <FontIcon
                  aria-label="Delete"
                  iconName="Delete"
                  className={iconClass}
                  onClick={() => {
                      dispatch(saveProjectDayPlanningStateAttr('isConfirmDeleteModalOpen', false));
                      dispatch(saveProjectDayPlanningStateAttr('selectedWorker', emp));
                  }}
                />
              </div>
            </span>
                    })
                }
                <Separator/>
            </div>
        } else {
            return <div></div>;
        }

    };

    const handleNullValue = (value: any) => {
        return value ? value : 0
    };

    const renderResource = (items: any, isTool: boolean) => {
        // console.log({items})
        if (items && items.length > 0) {
            return <div>
                {
                    items.map((item: any) => {
                        return <span style={{paddingBottom: 1, display: 'flex'}} key={useId()} id={useId('cpcItem')}>
              <div style={{display: 'flex'}}>
                <FontIcon
                  iconName={isTool ? 'DeveloperTools' : 'DeliveryTruck'}
                  className="ms-Icon"
                  style={styles.resourceLogo}
                />
                <span style={{fontSize: 10, marginLeft: 11, color: 'black'}}>{removeUniqueIds(item.title,true)}</span>
                  {isTool && <div className={'tool-count'}>
                      <span>{`${handleNullValue(parseInt(item?.allocatedQuantity))}/${handleNullValue(parseInt(item?.requiredQuantity))}`}</span>
                  </div>}
              </div>
            </span>
                    })
                }

                <Separator/>
            </div>
        } else {
            return <div></div>;
        }

    };

    const openPmol = (pmol: any) => {
        dispatch(saveStateAttr('contractingUinit', pmol?.contractingUinit));
        dispatch(saveStateAttr('projectSequenceCode', pmol?.projectSequenceCode));
        if (isDp) {
            dispatch(saveMyDayPlanningStateAttr('selectedMyDpPmol', pmol));
        } else {
            dispatch(saveProjectDayPlanningStateAttr('selectedPmol', pmol));
        }
    }

    const openTitlePopup = (pmol: any) => {
        // Get the current URL path
        let currentPath = window.location.pathname;

        // Check if the URL path contains 'day-planning-new'
        if (currentPath.includes(PROJECT_PLANNING_ROUTE)) {
            dispatch(saveProjectDayPlanningStateAttr('isOpenTitlePopup', true));
            dispatch(saveProjectDayPlanningStateAttr('selectedPmolToRenamed', pmol));
        } else {

        }
    }

    const openTimePopup = () => {
        let currentPath = window.location.pathname;
        // Check if the URL path contains 'day-planning-new'
        if (currentPath.includes(PROJECT_PLANNING_ROUTE)) {
            dispatch(saveProjectDayPlanningStateAttr('isPmolTimeChangeModelOpen', true))
            dispatch(saveProjectDayPlanningStateAttr('selectedPmolData', pmol))
        } else {

        }
    }

    const onClickConfirmDialog = (statusId: any) => {
        let data: any;
        if (isDp || isMyCal) {
            data = {
                projectId: pmol?.projectSequenceCode ? pmol?.projectSequenceCode : null,
                projectMoleculeId: pmol?.projectMoleculeId ? pmol?.projectMoleculeId : null,
                sequenceCode: pmol?.contractingUinit ? pmol?.contractingUinit : null,
                statusId: statusId
            };
        } else {
            data = {
                projectId: pmol?.projectSequenceCode ?? null,
                projectMoleculeId: pmol?.projectMoleculeId ? pmol?.projectMoleculeId : null,
                sequenceCode: pmol?.contractingUinit ? pmol?.contractingUinit : null,
                statusId: statusId
            };
        }

        handlePmolStatusChange(data);
    };

    const removeUniqueIds = (value : any, isResources: boolean) => {
        let title = "";  
        let parts = value.split(" - ");
        if(parts.length>0){
            if(!isResources){
                // value.includes("- PBS-") ||
                if(parts.length<3){
                    if(value.includes("PBS")){
                        title = parts[parts.length - 1];
                    } else {
                        title = value;
                    }
                    
                } else if(parts.length===3){
                    if(value.includes("- PBS-")){
                        title = parts[0]+' - '+parts[parts.length - 1];
                    } else {
                        title = value;
                    }
                    
                } else {
                    if(value.includes("- PBS-")){
                        const indexRemoved = parts.filter((_: any, index: number) => index !== 1);
                        title = indexRemoved.join(' ');
                    } else {
                        title = value;
                    }

                }
              return title;
            }
            else {
                const indexRemoved = parts.filter((_: any, index: number) => index !== 0);
                title = indexRemoved.join(' ');
                return title;
            }
        }
        else {
            return value;
        }
        
    }

    return <div className='event-body-container' ref={eventRef}
                style={{backgroundColor: '#ffffff', width: '100%', height: 'calc(100% - 25px)', overflow: 'hidden'}}>
        <div className='tooltip-text' ref={tooltipRef} id="tooltip-id">
      <span style={{color: '#000000'}}>
        {removeUniqueIds(props?.pmol?.productTaxonomy, false)}
      </span>
            <br/>
            <span style={{color: '#000000'}}>
        {removeUniqueIds(props?.pmol?.title, false)}
      </span>
        </div>
        <Stack horizontal styles={stackStyles} tokens={innerStackTokens}>
            <Stack.Item grow styles={stackItemStylesStatus}>
                <Popover popover={({visible, open, close}) => {
                    return (
                      <div>
                          <ChoiceGroup
                            label={t("pmolStatus")}
                            selectedKey={pmolStatusId}
                            options={pmolStatus}
                            onChange={(ev: any, item: any) => {
                                onClickConfirmDialog(item.key);
                            }}
                          />
                      </div>
                    );
                }
                }>
                    <FontIcon   //this component gives some errors on render
                      aria-label={`${t("changePmolStatus")}`}
                      iconName={iconName}
                      // onMouseEnter={() => {
                      //   setIsHover((isHover: boolean) => !isHover);
                      //   setIconName('Edit');
                      // }}
                      // onMouseLeave={() => {
                      //   setIsHover((isHover: boolean) => !isHover);
                      //   setIconName(`${getStatesIcon(pmol?.statusId) + '-' + getPmolType(pmol)}`);
                      // }}
                      className={`ms-Icon pie-chart`}
                    //   style={styles.logo}
                      style={pmol.isFinished?styles.done:styles.notDone}
                    />
                </Popover>
            </Stack.Item>
            <Stack.Item grow styles={nonShrinkingStackItemStyles}>
                <div style={{display: 'flex', flexDirection: 'row'}}>
                    <Label style={{
                        cursor: 'pointer',
                        width: '500px',
                        overflow: 'hidden',
                        whiteSpace: 'nowrap',
                        textOverflow: 'ellipsis'
                    }} className={'pmol-title'} onClick={useSingleAndDoubleClick(
                      () => openPmol(pmol),
                      () => openTitlePopup(pmol)
                    )}>
                        {removeUniqueIds(pmol?.title, false)} <br/>

                        <span style={{fontSize: 9}}>{pmol?.projectTitle}</span>
                    </Label>
                </div>
            </Stack.Item>
            <Stack.Item grow styles={stackItemStylesDot}>
                <CommandBarButton
                  ariaLabel="More items"
                  styles={buttonStyles}
                  onRenderMenuIcon={() => {
                      return <FontIcon
                        iconName={'dot-menu-' + getPmolType(pmol)}
                        className="ms-Icon"
                        style={styles.dot}
                      />
                  }}
                  menuProps={{items: overflowItems!}}
                />
            </Stack.Item>
        </Stack>

        <Stack horizontal styles={stackStylesTimeBar} tokens={innerStackTokens}>
            <Stack.Item>
                <span onClick={() => {
                    openTimePopup()
                }} className={'pmol-time'}>{pmol?.executionStartTime} - {pmol?.executionEndTime}</span>
            </Stack.Item>


            {!props.isMyCal && <Stack.Item>
                <div className="navbar-pmol" style={{display: 'flex', marginTop: '0px', fontSize: 12, fontWeight: 600}}>
                    <div className={'vertical-line'}>&nbsp;</div>
                    <div className="button" style={{ padding: 2, width: 20 }}>
                        <FontIcon
                            iconName={'People'}
                            className="ms-Icon"
                            style={styles.peopleLogo}
                            onClick={() => props.handleCompetencyModal("ppbWorkersId")}
                        />
                        {pmol?.team?.length > 0 ? <span className="button__badge">{pmol?.team?.length}</span> : ''}
                    </div>
                    <div className="button" style={{ padding: 2, width: 20 }}>
                        <FontIcon
                            iconName={'DeliveryTruck'}
                            className="ms-Icon"
                            style={styles.whetherLogo}
                            onClick={() => props.handleCompetencyModal("ppbVehicleId")}
                        />
                        {pmolVehical?.length > 0 ? <span className="button__badge">{pmolVehical?.length}</span> : ''}
                    </div>
                    <div className='button' style={{ padding: 2, width: 20 }}>
                        <FontIcon
                            iconName={'DeveloperTools'}
                            className="ms-Icon"
                            style={styles.whetherLogo}
                            onClick={() => props.handleCompetencyModal("ppbToolId")}
                        />
                        {pmolTool?.length > 0 ? <span className="button__badge">{pmolTool?.length}</span> : ''}
                    </div>
                </div>
            </Stack.Item>}
            <Stack.Item>
                <div style={{display: 'flex', marginTop: '0px', fontSize: 12, fontWeight: 600}}>
                    <div className={'vertical-line'}>&nbsp;</div>

                    <div style={{...styles.whetherCol, flexDirection: 'column', marginLeft: 2}}>
                        <TooltipHost
                          key={useId()}
                          content={pmol?.forecast?.phrase}
                          calloutProps={{gapSpace: 0}}
                          directionalHint={DirectionalHint.rightCenter}
                        >
                            <FontIcon
                              iconName={pmol?.forecast?.iconCode ? getWeatherIcon(pmol?.forecast?.iconCode) : ''}
                              className="ms-Icon"
                              style={styles.whetherLogo}
                            />
                        </TooltipHost>
                    </div>

                    <div style={{...styles.whetherCol}}>
                        {pmol?.forecast?.precipitationProbability &&
                            <span>{pmol?.forecast?.precipitationProbability}%</span>}
                    </div>

                    <div style={{paddingBottom: 3, ...styles.whetherCol}}>
                        {pmol?.forecast?.temperature &&
                            <span>{pmol?.forecast?.temperature?.maximum?.value?.toFixed()}<sup>°c</sup></span>}
                    </div>
                </div>
            </Stack.Item>
            <Stack.Item>
                <div style={{ display: 'flex', marginTop: '0px', fontSize: 12, fontWeight: 600 }}>
                    <div className={'vertical-line'}>&nbsp;</div>
                    <div style={{ ...styles.whetherCol, flexDirection: 'column', marginLeft: 2 }}>
                        <TooltipHost
                          key={useId()}
                          content={pmol?.comment ? <div dangerouslySetInnerHTML={{ __html: pmol?.comment }} /> : undefined}
                          calloutProps={{ gapSpace: 0 }}
                          directionalHint={DirectionalHint.rightCenter}
                        >
                            <FontIcon
                              iconName={pmol?.comment ? 'CommentActive' : 'Comment'}
                              className='ms-Icon'
                              style={styles.whetherLogo}
                            />
                        </TooltipHost>
                    </div>
                </div>
            </Stack.Item>
        </Stack>
        <Stack styles={stackStylesResource}>
            <div style={{
                overflow: 'auto',
                height: eventRef?.current?.offsetHeight ? (eventRef?.current?.offsetHeight - 100) : ''
            }}>
                {renderTeamMembers(pmol?.team)}
                {renderResource(pmolVehical, false)}
                {renderResource(pmolTool, true)}
            </div>
        </Stack>
    </div>
};

export default EventBody