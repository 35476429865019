import client from "../api";

const apiKey = 'https://graph.microsoft.com/';

export default class graphApiServices {

 sendEmailWithPdfAttachment = async (subject: string, recipient: string[], base64data: string, accessToken: any, userId: any) => {
        try {
          const response = await fetch(apiKey+'v1.0/users/'+userId+'/sendMail', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${accessToken}`,
            },
            body: JSON.stringify({
              message: {
                subject: subject,
                toRecipients: recipient.map(email => ({
                    emailAddress: {
                      address: email
                    }
                  })),
                body: {
                  contentType: 'Text',
                  content: ''
                },
                attachments: [
                  {
                    '@odata.type': '#microsoft.graph.fileAttachment',
                    'name': 'workerData.pdf',
                    'contentBytes': base64data,
                    "contentType": "application/pdf"
                  }
                ]
              }
            }),
          });    
        } catch (error) {
          
        }
      }

      getToken = async () => {
      const response = await client.get('ContractingUnitTenants/GenerateTokenUsingConnnection',{
        params: {
          ClientInfo: 'graph_API'
        }});

        return response?.data;
      }
}
