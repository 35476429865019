import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  ActionButton,
  getTheme,
  IIconProps,
  IStackItemStyles,
  IStackStyles,
  IStackTokens,
  Label,
  mergeStyleSets,
  Stack,
} from '@fluentui/react';
import { uPrinceTheme } from '../../../theme';
import ImagePreview from './ImagePreview';
import client from '../../api';

const theme = getTheme();

const iconButtonStyles = {
  root: { color: uPrinceTheme.palette.themePrimary },
  rootHovered: { color: theme.palette.neutralDark },
};
const uploadIcon: IIconProps = {
  iconName: 'Upload',
  styles: iconButtonStyles,
};

const copyIcon: IIconProps = {
  iconName: 'Copy',
  styles: iconButtonStyles,
};
const deleteWhiteIconButtonStyles = {
  root: {
      color: uPrinceTheme.palette.white,
      width: 17,
      minWidth: 17,
      height: 15,
      paddingRight: 1,
      paddingLeft: 1,
      paddingTop: 1,
      paddingBottom: 1
  },
  rootHovered: { color: theme.palette.neutralDark }
};
const deleteIconWhite: IIconProps = {
  iconName: 'Delete',
  styles: deleteWhiteIconButtonStyles
};
const stackStyles: IStackStyles = { root: { padding: 0, boxSizing:'border-box', justifyContent: 'space-between' } };
const stackItemStyles: IStackItemStyles = {
  root: {
    display: 'flex',
    height: 40,
    alignItems: 'flex-start',
  },
};

const stackEditbarItemStyles: IStackItemStyles = {
  root: {
    display: 'flex',
    height: 45,
    alignItems: 'flex-end',
  },
};

const extraSpace: IStackItemStyles = {
  root: {
    marginRight: '30%'
  }
}
const stackTokens: IStackTokens = {
  childrenGap: 15,
  padding: 10,
};

const classNames = mergeStyleSets({
  wrapper: {
    // height: '100vh',
    position: 'relative',
    maxHeight: 'inherit',
  },
  pane: { maxWidth: 400 },
  textContent: { padding: '15px 10px' },
  actionIcon: {
    padding: 5,
    cursor: 'pointer',
  },
  labelRightWrapper: {
    textAlign: 'end',
    selectors: { '@media(max-width: 1100px)': { textAlign: 'start' } },
  },
  actionButton: {
    color: uPrinceTheme.palette.themePrimary,
    cursor: 'pointer',
  },
  labelRightIcon: {
    display: 'inline-block',
    verticalAlign: 'bottom',
    paddingBottom: 2,
    paddingRight: 5,
  },
});

const CustomFileUploader = (props: {
  isImage?: boolean;
  label: any;
  uploadedImages: any;
  uploadUrl: string;
  /* setJournalImage:(
        type:string,response:any) => void; */
  onSaveSuccess: (response: any) => void;
  onSaveError?: () => void;
  isDelete?: boolean;
  handleImageDelete?: (id: string) => void;
  renameSuccess?:() => void;
  disable?: boolean;
  checkedItems?: string[];
  productId?: any;
  showCopyToPbs?:boolean;
  showCheckbox?:boolean;
  showRename?:boolean;
  sectionHeading?:string;
  isDragEnabled?: boolean
  showDelete?:boolean;

}) => {
  const { t } = useTranslation();
  const [dragging, setDragging] = useState(false);
  const [selectedFiles, setFiles]: any = useState(props.uploadedImages);
  const [transition, setTransition]: any = useState(false);
  const [checkedCopyItems, setCheckedCopyItems]: any = useState<any[]>([]);
  const [isDisabledCTPBS, setIsDisabledCTPBS] = useState<boolean>(false);
  const [isDragAndDropEnabled, setIsDragAndDropEnabled] = useState<boolean>(false);
  
  const inputFileRef = useRef<any>(null);

  useEffect(() => {
    if (props.isDragEnabled) {
      setIsDragAndDropEnabled(props.isDragEnabled)
    }
  }, [props.isDragEnabled])
  
  const handleCheckedItemsUpdate = (updatedCheckedItems: any) => {
    // setCheckedCopyItems((checkedCopyItems: any) => [...checkedCopyItems, updatedCheckedItems]);
    setCheckedCopyItems((prevItems: any) => {
      const existingIndex = prevItems.findIndex((detail: any) => detail.imageId === updatedCheckedItems.imageId);
      if (existingIndex !== -1) {          
          prevItems.splice(existingIndex, 1);
      }
      return existingIndex === -1 ? [...prevItems, updatedCheckedItems]: prevItems;
  });  
  };

  const handleCheckboxClick = (itemId: any) => {
    const updatedImages = props.uploadedImages.map((image: any) =>
    image.id === itemId ? { ...image, isChecked: true } : image
  );
  setFiles(updatedImages);
  };  
  useEffect(() => {
    setFiles(props.uploadedImages);
    setIsDisabledCTPBS(props.uploadedImages.length === 0);
  }, [props.uploadedImages]);

  const showFileDialog = () => {
    inputFileRef.current && inputFileRef.current.click();
  };

  const handleDelete = (image: string) => {
    const pictureList = checkedCopyItems.map((item: any) => ({
      link: item.link,
      type: item.type
    }));
    const copyImgData = {
      id: "",
      description: "",
      pbsProductId: props.productId,
      pictureList: pictureList
    }    
    console.log(props.uploadedImages)
    const updatedFiles = selectedFiles.filter((file: any) => file.image !== image);
    setFiles(updatedFiles);
    if (props.handleImageDelete) {
      props.handleImageDelete(image);
    }
  };
  const handleCopyToPBSClick = () => { 
    const journalImgIds = checkedCopyItems.filter((item: any) => item.isJournal).map((item: any) => item.imageId);
    const extraworkIds = checkedCopyItems.filter((item: any) => item.isExtrawork).map((item: any) => item.imageId);
    const pictureList = checkedCopyItems.map((item: any) => ({
      link: item.link,
      type: item.type
    }));
    const copyImgData = {
      id: "",
      description: "",
      pbsProductId: props.productId,
      pictureList: pictureList
    }    

    if(copyImgData && copyImgData.pbsProductId!=null && copyImgData.pictureList.length>0){
      copyToProductComposition(copyImgData);
    }

    if(extraworkIds && extraworkIds.length>0){
      const idList = {
        imageIds: extraworkIds
      }
      approveExtraImages(idList);
    }

    if(journalImgIds && journalImgIds.length>0){
      const idList = {
        imageIds: journalImgIds
      }
      approveJournalImages(idList);
    }

    const currentFiles = selectedFiles.map((item: any) => {
      if(extraworkIds?.includes(item.id) || journalImgIds?.includes(item.id)){
        return { image: item.image, id: item.id, isLast: false, extension: item.extension ? item.extension : null, isApproved : true };
      }
      else{
        return { image: item.image, id: item.id, isLast: false, extension: item.extension ? item.extension : null, isApproved : item.isApproved };
      }      
    });

    setFiles(currentFiles);

  };

  const copyToProductComposition =(copyImgData: any) =>{
    client.post("Pbs/CopyPicturesToComposition", copyImgData).then(
      (response: any) => {
        
      },

      (error: any) => {
        alert("not working");
      },
    );
  }

  const approveExtraImages =(imageIds: any) =>{
    client.post("Pmol/ApproveImages", imageIds).then(
      (response: any) => {

      },

      (error: any) => {
        alert("Extra not working");
      },
    );
  }

  const approveJournalImages =(imageIds: any) =>{
    client.post("PmolJournal/ApproveJournalImages", imageIds).then(
      (response: any) => {

      },

      (error: any) => {
        alert("Journal not working");
      },
    );
  }



  const uploadFiles = (files: FileList | null) => {
    // files && context.uploadItems(files);
    if (selectedFiles) {
      const currentFiles = selectedFiles.map((item: any) => {
        return { image: item.image, id: item.id, isLast: false, extension: item.extension ? item.extension : null };
      });
      if (files) {
        let parts = files[0].name.split('.');
        const size = files[0].size;
        // alert(size);
        setFiles(
          currentFiles.concat([
            { image: URL.createObjectURL(files[0]), id: null, isLast: true, extension: parts[parts.length - 1] },
          ]),
        );
        setTransition(true);
        saveImage(files, currentFiles);
      }
    }
  };

  const saveImage = (files: any, currentFiles: any) => {
    const headers = { 'Content-Type': 'multipart/form-data' };

    const data = new FormData();
    if (files) {
      for (const file of files) {
        data.append('image', file);
      }
    }
    client.post(props.uploadUrl, data, { headers: headers }).then(
      (response: any) => {
        setTransition(false);
        props.onSaveSuccess(response.data.result);
      },

      (error: any) => {
        alert(JSON.stringify(error));
        setTransition(false);
        setFiles(props.uploadedImages);
      },
    );
  };

  // Functions for drag and drop
  const handleDragEnter = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setDragging(true);
  };

  const handleDragLeave = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setDragging(false);
  };

  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.dataTransfer.dropEffect = 'copy'; // Explicitly show this is a copy
    setDragging(true);
  };

  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setDragging(false);
    // const droppedFiles = Array.from(e.dataTransfer.files);
    isDragAndDropEnabled && uploadFiles(e.dataTransfer.files)
  };
  
  return (    
    <div  className={`dropzone ${dragging ? 'dragging' : ''} input-file`} 
    onDragEnter={handleDragEnter}
    onDragOver={handleDragOver}
    onDragLeave={handleDragLeave}
    onDrop={handleDrop}>
      <div>
        <input
          style={{ display: 'none' }}
          ref={inputFileRef}
          type="file"
          id='file'
          accept={props.isImage ? 'image/*' : '*'}
          multiple={false}
          onChange={(e) => {
            uploadFiles(e.target.files);
            // props.setJournalImage('link',e)
          }}
        />
      </div>
      <Stack
        horizontal
        // horizontalAlign="space-between"
        styles={stackStyles}
        tokens={stackTokens}
      >
        <Stack.Item styles={stackEditbarItemStyles}>
          <ActionButton
          className={classNames.actionButton}
          >
            <Label>{props.label}</Label>
          </ActionButton>          
        </Stack.Item>
        {props.showCopyToPbs && (
        <Stack.Item styles={extraSpace}>
          <Label ></Label>
        </Stack.Item>
        )}
        {props.showDelete && (
        <Stack.Item styles={extraSpace}>
          <Label ></Label>
        </Stack.Item>
        )}
        <Stack.Item styles={stackEditbarItemStyles}>
            <ActionButton
              className={classNames.actionButton}
              iconProps={uploadIcon}
              allowDisabledFocus
              // disabled={props.disable}
              onClick={() => {
                showFileDialog();
              }}
            >
              <Label className={classNames.actionButton}>{t('upload')}</Label>
            </ActionButton>
        </Stack.Item>
        {props.showCopyToPbs && (
        <Stack.Item styles={stackEditbarItemStyles}>
          <ActionButton
            className={classNames.actionButton}
            iconProps={copyIcon}
            allowDisabledFocus={isDisabledCTPBS}
            disabled={isDisabledCTPBS}
            style={ {opacity: isDisabledCTPBS ? 0.5 : 1} }      
            onClick={() => {
              handleCopyToPBSClick();
            }}
          >
          <Label className={classNames.actionButton}>{t('Copy to PBS')}</Label>
          </ActionButton>        
        </Stack.Item>
        )}
         {props.showDelete && (
        <Stack.Item styles={stackEditbarItemStyles}>
          <ActionButton
            className={classNames.actionButton}
            iconProps={deleteIconWhite}
            // allowDisabledFocus={isDisabledCTPBS}
            // disabled={isDisabledCTPBS}
            // style={ {opacity: isDisabledCTPBS ? 0.5 : 1} }      
            onClick={() => {
              handleDelete(selectedFiles[0].image);
            }}
          >
          <Label className={classNames.actionButton}>{t('Delete')}</Label>
          </ActionButton>        
        </Stack.Item>
        )}
      </Stack>
      <div>
        <ImagePreview
          transition={transition}
          uploadedImages={selectedFiles}
          handleImageDelete={(id: string) => {
            if (props.handleImageDelete) {
              props.handleImageDelete(id);
            }
          } }
          disable={props.disable}
          isDelete={props.isDelete}
          onCheckedItemsUpdate={handleCheckedItemsUpdate}
          showCheckbox={props.showCheckbox}
          showRename={props.showRename}
          sectionHeading={props.sectionHeading} 
          renameSuccess={(item: any) => {
            if(item && props.renameSuccess){
              // props.renameSuccess(item);
              props.renameSuccess();
            }            
          }}
          // showDelete={props.showDelete}
          />
      </div>
    </div>
  );
};

export default CustomFileUploader;

export interface ImageDetail {
  journalWork: {
      imageId: string[]; 
      pmolId: string;
      journalId: string;
      journalWorkId: string;
  };
  extraWork: {
      imageId: string[];
      pmolId: string;
      journalId: string;
      extraWorkId: string;
  };
}
