import { useTranslation } from 'react-i18next';
import {
  FontWeights,
  getTheme,
  IconButton,
  IIconProps,
  mergeStyleSets,
  Modal,
  PrimaryButton,
  Separator,
} from '@fluentui/react';
import React, { useEffect, useState } from 'react';
import { useId } from '@uifabric/react-hooks';
import { uPrinceTheme } from '../../../../../theme';
import { useDispatch, useSelector } from 'react-redux';
import { getHtmlPrintData, saveProjectDayPlanningStateAttr } from '../../../../reducers/projectDayPlanningReducer';
import { Form } from 'react-final-form';
import ProgressBar from '../../../../shared/progressBar/progressBar';
import ReactToPrint from 'react-to-print';
import moment from 'moment';
import { formatDateTimeToISOString, isMyEnvironment } from '../../../../shared/util';
import {Document, Image, Page, pdf, StyleSheet, Text, View} from '@react-pdf/renderer';
import graphApiServices from '../../../../services/graph.service';

const theme = getTheme();

const cancelIcon: IIconProps = { iconName: 'Cancel' };

const addWhiteIconButtonStyles = {
  root: {
    color: uPrinceTheme.palette.white,
  },
  rootHovered: {
    color: theme.palette.neutralDark,
  },
};

const addIconWhite: IIconProps = {
  iconName: 'Print',
  styles: addWhiteIconButtonStyles,
};
const mailIcon: IIconProps = { iconName: 'Mail', styles: addWhiteIconButtonStyles };

const styles = StyleSheet.create({
  table: {
    display: 'table',
    // borderStyle: 'solid',
    // borderWidth: 1,
    // borderColor: '#bfbfbf',
    margin: 10,
    width: 'auto',
    fontSize: 11,
  },
  tableRow: {
    flexDirection: 'row',
  },
  tableCol: {
    width: '14.2%',
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: '#dddddd',
    textAlign: 'left',
  },
  tableData: {
    width: '14.2%',
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: '#dddddd',
    textAlign: 'left',
    fontWeight:'light',
  },
  tableCell: {
    margin: 5,
    fontSize: 9,
    display: 'flex',
    flexDirection: 'row'
  },
  header: {
    fontWeight: 'bold',
  },
});

const contentStyles = mergeStyleSets({
  container: {
    display: 'flex',
    flexFlow: 'column nowrap',
    alignItems: 'stretch',
  },
  actionButtonLabel: {
    color: uPrinceTheme.palette.themePrimary,
  },
  header: [
    // eslint-disable-next-line deprecation/deprecation
    theme.fonts.xLargePlus,
    {
      flex: '1 1 auto',
      display: 'flex',
      alignItems: 'center',
      fontWeight: FontWeights.semibold,
      padding: '2px 2px 2px 10px',
      backgroundColor: uPrinceTheme.palette.themePrimary,
      fontSize: 18,
      color: 'white',
    },
  ],
  footer: [
    // eslint-disable-next-line deprecation/deprecation
    theme.fonts.xLargePlus,
    {
      flex: '1 1 auto',
      alignItems: 'center',
      fontWeight: FontWeights.semibold,
      padding: '2px 26px 2px 2px',
      textAlign: 'end',
      borderTop: '1px solid #dddddd',
      background: 'white'
    },
  ],
  body: {
    flex: '4 4 auto',
    padding: '0 24px 0px 24px',
    overflow: 'auto',
    minWidth: '20vw',
    minHeight: '10vh',
    maxWidth: '50vw',
    maxHeight: '76vh',
    selectors: {
      p: { margin: '14px 0' },
      'p:first-child': { marginTop: 0 },
      'p:last-child': { marginBottom: 0 },
    },
  },
  subHeader: {
    flex: '1 1 auto',
    display: 'block',
    alignItems: 'center',
    fontWeight: FontWeights.semibold,
    padding: '2px 2px 2px 10px',
    fontSize: 14,
    color: uPrinceTheme.palette.themePrimary,
    backgroundColor: '#FFF7F4',
    height: 40,
    marginLeft: 'auto',
    marginRight: '2px',
  },
});

const tableStyle = mergeStyleSets({
  table: {
    marginTop: 10,
    borderCollapse: 'collapse',
    width: '100%',
    fontSize: 11,
  },
  td: {
    border: '1px solid #dddddd',
    textAlign: 'left',
    padding: 8,
    verticalAlign: 'top',
  },
  tdProjectName: {
    border: '1px solid #dddddd',
    textAlign: 'left',
    padding: 8,
    verticalAlign: 'top',
    width: 120,
  },
  th: {
    border: '1px solid #dddddd',
    textAlign: 'left',
    textAlignLast: 'center',
    padding: 8,
  },
  thHeading: {
    // border: '1px solid #dddddd',
    textAlign: 'center',
    padding: 8,
    fontWeight: 'bold',
  },
  even: {
    backgroundColor: '#dddddd',
  },
});

const cancelIconButtonStyles = {
  root: {
    marginLeft: 'auto',
    marginTop: '4px',
    marginRight: '2px',
    color: 'white',
  },
  rootHovered: {
    color: theme.palette.neutralDark,
  },
};

const PrintModal = () => {

  const dispatch = useDispatch();
  const graphApi = new graphApiServices();
  const { t } = useTranslation();
  const titleId = useId('title');
  const { isPrintModelOpen } = useSelector((state: any) => state.projectDPlan);
  const { selectedDate } = useSelector((state: any) => state.mydPlan);
  const { htmlPrintData } = useSelector((state: any) => state.projectDPlan);
  const { showProgressBar } = useSelector((state: any) => state.projectDPlan);
  const { selectedBu } = useSelector((state: any) => state.projectDPlan);
  const { selectedCu } = useSelector((state: any) => state.projectDPlan);
  const { selectedProject } = useSelector((state: any) => state.project);
  const {userInfo} = useSelector((state: any) => state.uprince);

  const [emailIds, setEmailIds] = useState<any>([]);

  const componentRef = React.useRef<any>(null);

  const reactToPrintContent = React.useCallback(() => {
    return componentRef.current;
  }, [componentRef.current]);

  const reactToPrintTrigger = React.useCallback(() => {
    return (
      <PrimaryButton
        iconProps={addIconWhite}
        text={t('print')}
        disabled={false}
        style={{ marginTop: 10, marginBottom: 10 }}
      />
    );
  }, []);

  useEffect(() => {
    if (isPrintModelOpen) {
      dispatch(getHtmlPrintData({
        date: formatDateTimeToISOString(selectedDate),
        buId: isMyEnvironment() ? selectedBu : selectedProject?.projectClassification?.projectClassificationBuisnessUnit,
        cu: selectedCu?.sequenceCode,
      }));
    }
  }, [isPrintModelOpen]);

  useEffect(() => {
    if(htmlPrintData){
      const emailIds = Array.from(
        new Set(
          htmlPrintData
            .flatMap((row: any) => row.names.map((name: any) => name.emailId))
            .filter((emailId: any) => emailId)));
      // const emailIds = htmlPrintData?.flatMap((row: any) => row.names.map((value: any) => value.emailId)).filter((emailId: any) => emailId);
      setEmailIds(emailIds);
    }    
  }, [htmlPrintData]);


  const onSubmit = async (values: any) => {
    // const sleep = (ms: number | undefined) => new Promise(resolve => setTimeout(resolve, ms));
    // await sleep(300);
    //
    // Promise.all([
    //   // dispatch(updatePlanBoardsPmolTitle(values))
    // ]).then(() => {
    //   onModalClose();
    // });
  };


  const onModalClose = () => {
    dispatch(saveProjectDayPlanningStateAttr('isPrintModelOpen', false));
  };

  const mailContent = () => {    
    const content = htmlPrintData ? htmlPrintData : [];
    return (
    <Document>
      <Page size="A4" orientation="landscape">
        {/* <View> */}
          <Text style={{textAlign: 'center', padding: 8, fontSize: 10}}>{moment(selectedDate).format('dddd MMMM D')}</Text>
          <View style={styles.table}>
            <View style={[styles.tableRow, styles.header]}>
              {/* <View style={styles.tableCol}><Text style={styles.tableCell}>{t('project')}</Text></View> */}
              <View style={styles.tableCol}><Text style={styles.tableCell}>{t('hour')}</Text></View>
              <View style={styles.tableCol}><Text style={styles.tableCell}>{t('projectMolecule')}</Text></View>
              <View style={styles.tableCol}><Text style={styles.tableCell}>{t('siteManager')}</Text></View>
              <View style={styles.tableCol}><Text style={styles.tableCell}>{t('names')}</Text></View>
              <View style={styles.tableCol}><Text style={styles.tableCell}>{t('material')}</Text></View>
              <View style={styles.tableCol}><Text style={styles.tableCell}>{t('vehicles')}</Text></View>
              <View style={styles.tableCol}><Text style={styles.tableCell}>{t('address')}</Text></View>
            </View>
              {content.map((row: any, index: any) => (
                <View key={index} style={styles.tableRow}>
                  {/* <View style={styles.tableData}><Text style={styles.tableCell}>{row.project}</Text></View> */}
                  <View style={styles.tableData}><Text style={styles.tableCell}>{row.hour}</Text></View>
                  <View style={styles.tableData}><Text style={styles.tableCell}>{removeResourceOrIds(row.projectMolecule)}</Text></View>
                  <View style={styles.tableData}><Text style={styles.tableCell}>{row.siteManager}</Text></View>
                  <View style={styles.tableData}>
                    {row.names?.map((name: any, i: any)=> (
                      <Text key={i} style={styles.tableCell}>{name?.fullName}</Text>
                    ))}
                  </View>
                  <View style={styles.tableData}>
                    {row.material?.map((material: any, i: any)=> (
                      <Text key={i} style={styles.tableCell}>{removeResourceOrIds(material)}</Text>
                    ))}
                  </View>
                  <View style={styles.tableData}>
                    {row.vehicle?.map((vehicle: any, i: any)=> (
                      <Text key={i} style={styles.tableCell}>{removeResourceOrIds(vehicle)}</Text>
                    ))}
                  </View>
                  <View style={styles.tableData}><Text style={styles.tableCell}>{row.address}</Text></View>
                </View>
              ))}
          </View>
        {/* </View> */}
      </Page>
    </Document>
    );
  }
  
  // Download html table as pdf 
  const downloadContentAsPdf = async () => {
    try {
        const blobPdf = await pdf(mailContent()).toBlob();
        const blobData = new Blob([blobPdf], {type: 'application/pdf'});
        const formData = new FormData();
        formData.append('pdf', blobData,'workerData.pdf');
        console.log(formData);

        // Create a URL for the PDF blob
        const url = window.URL.createObjectURL(blobData);

        // Create a temporary link element
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'workerData.pdf');

        // Append link to the body
        document.body.appendChild(link);

        // Trigger the download by clicking the link
        link.click();

        // Remove the link from the document
        document.body.removeChild(link);
    } catch (err) {
        console.log(err);
    }
  };

  const sendMailToWorkerByGraphAPI = async () => {
    const subject = 'Potteau dagplanning ('+moment(selectedDate).format('dddd MMMM D')+')';
    try {
      const accessToken = await graphApi.getToken();
      const blobPdf = await pdf(mailContent()).toBlob();
       // Convert blob to base64 encoded string
      const reader = new FileReader();
      reader.readAsDataURL(blobPdf);
      reader.onloadend = async () => {
      const base64data = reader.result?.toString().split(',')[1];
      if(base64data && emailIds){
        // Call the Graph API method to send email
        await graphApi.sendEmailWithPdfAttachment(subject, emailIds, base64data, accessToken, userInfo.oid);
        onModalClose();
      }
      };
      
    } catch (err) {
      console.error('Error generating or sending PDF:', err);
    }
  }

  const removeResourceOrIds = (value : any) => {
    if(value){
      let parts = value.split(" - ");
      let lastValue = parts[parts.length - 1];
      return lastValue;
    }
  }

  return (
    <div>
      <Form
        onSubmit={onSubmit}
        initialValues={() => {
        }}
        render={({
                   handleSubmit,
                   form,
                   submitting,
                   pristine,
                   values,
                 }) => {
          return (
            <form onSubmit={handleSubmit} noValidate>
              <div>
                <Modal
                  titleAriaId={titleId}
                  isOpen={isPrintModelOpen}
                  onDismiss={() => {
                    onModalClose();
                  }}
                  // scrollableContentClassName='body'
                  isBlocking={false}
                  dragOptions={undefined}
                >
                  <div className={contentStyles.header}>
                    <span id={titleId}>{t('print')}</span>
                    <IconButton
                      styles={cancelIconButtonStyles}
                      iconProps={cancelIcon}
                      ariaLabel='Close popup modal'
                      onClick={() => {
                        onModalClose();
                      }}
                    />
                  </div>
                  <ProgressBar show={showProgressBar} />

                  <div className={contentStyles.body}>

                    <div className='proj-detail-content inner'>
                      <div className='ms-Grid-row'>

                        <div id='print-area-2' className='print-area' ref={componentRef}>
                          <table className={tableStyle.table}>
                            <thead>
                            <tr>
                              <td colSpan={8}
                                  className={tableStyle.thHeading}>{moment(selectedDate).format('dddd MMMM D')}</td>
                            </tr>
                            <tr>
                              {/* <th className={tableStyle.th}>{t('project')}</th> */}
                              <th className={tableStyle.th}>{t('hour')}</th>
                              <th className={tableStyle.th}>{t('projectMolecule')}</th>
                              <th className={tableStyle.th}>{t('siteManager')}</th>
                              <th className={tableStyle.th}>{t('names')}</th>
                              <th className={tableStyle.th}>{t('material')}</th>
                              <th className={tableStyle.th}>{t('vehicles')}</th>
                              <th className={tableStyle.th}>{t('address')}</th>
                            </tr>
                            </thead>
                            <tbody>
                            {htmlPrintData?.map((row: any) => (
                              // <tr className={ index % 2 === 0 ? tableStyle.even : '' }>
                              <tr>
                                {/* <td className={tableStyle.tdProjectName}>{row.project}</td> */}
                                <td className={tableStyle.td}>{row?.hour}</td>
                                <td className={tableStyle.td}>{removeResourceOrIds(row?.projectMolecule)}</td>
                                <td className={tableStyle.td}>{row?.siteManager}</td>
                                <td className={tableStyle.td}>
                                  {row.names?.map((name: any, i: any) => (
                                    <div key={i}>{name?.fullName}</div>
                                  ))}
                                </td>
                                <td className={tableStyle.td}>
                                  {row.material?.map((material: any, i: any) => (
                                    <div key={i}>{removeResourceOrIds(material)}</div>
                                  ))}
                                </td>
                                <td className={tableStyle.td}>
                                  {row.vehicle?.map((vehicle: any, i: any) => (
                                    <div key={i}>{removeResourceOrIds(vehicle)}</div>
                                  ))}
                                </td>
                                <td className={tableStyle.td}>{row?.address}</td>
                              </tr>
                            ))}
                            </tbody>
                          </table>
                        </div>

                      </div>
                    </div>

                  </div>
                  
                  <div className={contentStyles.footer}>
                    <PrimaryButton
                      iconProps={mailIcon}
                      text={t('send')}
                      disabled={false}
                      style={{ marginTop: 10, marginBottom: 10, marginRight: 25 }}
                      onClick={ () => {
                        // downloadContentAsPdf()
                        sendMailToWorkerByGraphAPI();
                      }}
                    />

                    <ReactToPrint
                      trigger={reactToPrintTrigger}
                      content={reactToPrintContent}
                      documentTitle={t('projectPlanboard') + ' ' + moment(selectedDate).format('DD-MM-YYYY')}
                      copyStyles={true}
                    />

                  </div>
                </Modal>
              </div>
            </form>
          );
        }}
      />
    </div>
  );
};

export default PrintModal;